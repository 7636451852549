import React from 'react';
import { Col, Row, Tag, Tooltip } from 'antd';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { PiHandTap } from 'react-icons/pi';
import { HiMiniPaintBrush } from 'react-icons/hi2';
import isEmpty from 'lodash/isEmpty';
import PreviewInfo from '../PreviewInfo';
import PreviewImages from '../PreviewImages';
import mapImageTypeToColor from '../../../../lib/mapImageTypeToColor';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import { HasRights } from '../../../../components/HasRights/HasRights';
import { Permissions } from '../../../../const/permissions';
import UpdateImageCategoriesModalForm from '../../forms/UpdateImageCategoriesModalForm';
import UpdateCollectionImageModalForm from '../../forms/UpdateCollectionImageModalForm';



const PreviewImageCard = ({
  imageInitialValues,
  initialImageCategoriesValues,
  data,
  onModalClose,
  attachmentModelData = {},
  categories = [],
  canUpdate = true,
  isImageAttaching = false,
}) => {
  const intl = useIntl();
  const canViewBeepanel = HasRights([ Permissions.CONTENT.IMAGES.VIEW_BEEPANEL ]);

  let beeLink = <span>{data.source.data.source_item_id}</span>;

  if (canViewBeepanel) {
    beeLink = <a href={data.source.data.link} target="_blank" rel="noreferrer">{data.source.data.source_item_id}</a>;
  }

  return (
    <>
      <Row gutter={[ 16, 16 ]} justify="space-around">
        <Col>
          <PreviewInfo
            title={intl.formatMessage({ id: 'ui-general-id' })}
            value={data.id}
          />
        </Col>
        <Col>
          <PreviewInfo
            title={intl.formatMessage({ id: 'ui-general-bee-id' })}
            value={beeLink}
          />
        </Col>
        <Col>
          <PreviewInfo
            title={intl.formatMessage({ id: 'ui-general-type' })}
            value={(
              <Tag style={{ marginRight: 0 }} color={mapImageTypeToColor(data.source.data.type_content)}>
                {data.source.data.type_content}
              </Tag>
            )}
          />
        </Col>
        <Col>
          <PreviewInfo
            title={intl.formatMessage({ id: 'content-image-preview-taps-label' })}
            value={(
              <div className="hp-d-flex hp-align-items-center">
                <Tooltip placement="top" title={<IntlMessages id="content-image-taps" />}>
                  <span className="content-stat-info">
                    <PiHandTap />
                    {data.data?.data[0]?.taps}
                  </span>
                </Tooltip>
                /
                <Tooltip placement="top" title={<IntlMessages id="content-image-colors-amount" />}>
                  <span className="content-stat-info">
                    <HiMiniPaintBrush />
                    {data.data?.data[0]?.colors}
                  </span>
                </Tooltip>
              </div>
            )}
          />
        </Col>
        <Col>
          <PreviewInfo
            title={intl.formatMessage({ id: 'ui-general-created-at' })}
            value={data.created_at}
          />
        </Col>
      </Row>
      <Row gutter={32} justify="center" className='hp-my-16 hp-border-top-1'>
        <PreviewImages
          icon={data.icon.data.path}
          preview={data.preview.data.path}
          fullWidth={!canUpdate}
        />
        {canUpdate && (
          <Col xs={24} sm={24} md={10} lg={10} xl={10}>
            {!isEmpty(attachmentModelData) && isEmpty(categories) ? (
              <UpdateCollectionImageModalForm
                initialValues={imageInitialValues}
                imageId={data?.id}
                collectionDetails={attachmentModelData}
                onModalClose={onModalClose}
                isImageAttaching={isImageAttaching}
              />
            ) : (
              <UpdateImageCategoriesModalForm
                initialValues={initialImageCategoriesValues}
                imageId={data?.id}
                imageCategories={data?.categories?.data}
                allCategories={categories}
                onModalClose={onModalClose}
              />
            )}
          </Col>
        )}
      </Row>
    </>
  );
};

PreviewImageCard.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.any,
    icon: PropTypes.object,
    preview: PropTypes.object,
    created_at: PropTypes.string,
    data: PropTypes.object,
    source: PropTypes.object,
    categories: PropTypes.object,
  }),
  onModalClose: PropTypes.func,
  categories: PropTypes.array,
  attachmentModelData: PropTypes.object,
  imageInitialValues: PropTypes.object,
  initialImageCategoriesValues: PropTypes.object,
  canUpdate: PropTypes.bool,
  isImageAttaching: PropTypes.bool,
};

export default PreviewImageCard;
