import React, { useEffect, useState } from 'react';
import { Col, Row, Space, Pagination, Modal, Tooltip, Popconfirm } from 'antd';
import { Filter } from 'iconsax-react';
import { RiCloseFill, RiErrorWarningLine } from 'react-icons/ri';
import { CopyOutlined, DownloadOutlined, SyncOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { HasRights } from '../../../../../components/HasRights/HasRights';
import { Permissions } from '../../../../../const/permissions';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import appColors from '../../../../../const/colors';
import urlDownloadImageArchive from '../../../../../urls/urlDownloadImageArchive';
import BreadCrumbs from '../../../../../layout/components/breadcrumbs';
import ActionButton from '../../../../../layout/components/action-button';
import Spinner from '../../../../../components/Spinner';
import Sidebar from '../../../../../components/Sidebar';
import useToggle from '../../../../../app/hooks/useToggle';
import ContentCard from '../../../components/ContentCard';
import FormImagesFilter from '../../../forms/FormImagesFilter';
import EmptyDataPlaceholder from '../../../../../components/EmptyDataPlaceholder';
import PreviewImageCard from '../../../components/PreviewImageCard';
import useQueryParams from '../../../../../app/hooks/useQueryParams';
import useDownload from '../../../../../app/hooks/useDownload';
import getQueryParams from '../../../../../lib/getQueryParams';
import applyFilter from '../../../../../lib/applyFilter';
import resetFilter from '../../../../../lib/resetFilter';
import handleResponse from '../../../../../lib/handleResponse';
import TotalLabel from '../../../../../components/TotalLabel';
import {
  useCopyImageMutation,
  useGetImagesQuery,
  useReopenImageMutation,
} from '../../../api/imagesApiSlice';
import { useGetCategoriesQuery } from '../../../api/categoriesApiSlice';



const PageImages = () => {
  const intl = useIntl();
  const [ filterOpen, toggleFilterSidebar ] = useToggle();
  const [ previewModalOpen, togglePreviewModal ] = useToggle();
  const [ showContour, toggleShowContour ] = useToggle();
  const [ previewImage, setPreviewImage ] = useState({});
  const [ initFilterValues, setInitFilterValues ] = useState({});
  const [ searchQueryParams, setSearchParams ] = useSearchParams();
  const { download, isLoading: isDownloading } = useDownload({ method: 'POST' });
  const [ initialImageCategoriesValues, setInitialImageCategoriesValues ] = useState({});

  const searchFilterFields = [ 'id', 'folder', 'category', 'main_category', 'image_type', 'status', 'tags', 'taps_from', 'taps_to', 'colors_from', 'colors_to', 'imported_from', 'imported_to', 'released_from', 'released_to' ];

  const {
    pagination,
    searchParams,
    handleChangeTableParams,
  } = useQueryParams({
    searchFilterFields,
    isFilter: true,
    paginationInit: { page: 1, limit: 48 } });

  const { data: {
    data: images = [],
    pagination: dataPagination = {},
  } = { data: [], pagination: {} }, isFetching } = useGetImagesQuery({
    queryParams: `${searchParams.toString()}`,
  });

  const { data: {
    data: categories = [],
  } = { data: [], pagination: {} }, isFetching: isCategoriesFetching } = useGetCategoriesQuery({
    queryParams: 'limit=0',
  });

  const [ copyImage, { isLoading: isCopying } ] = useCopyImageMutation();
  const [ reopenImage, { isLoading: isReopening } ] = useReopenImageMutation();

  const handleCardClick = (item) => {
    setPreviewImage(item);
    togglePreviewModal();
  };

  const getInitialImageCategoriesValues = () => {
    const initialValues = {
      categories: [],
      main_category: null,
      unique_id: Date.now(),
    };

    categories?.forEach((category) => {
      const currentImageCategory = previewImage?.categories?.data.find((imageCategory) => imageCategory.id === category.id);

      if (currentImageCategory) {
        if (currentImageCategory.is_main) {
          initialValues.main_category = currentImageCategory.id;
        }

        initialValues.categories.push(currentImageCategory.id);
      }
    });

    return initialValues;
  };

  useEffect(() => {
    setInitialImageCategoriesValues(getInitialImageCategoriesValues());
  }, [ previewImage ]);

  const handleDownload = (id) => {
    download(urlDownloadImageArchive({ id }));
  };

  const handleCopy = (id) => {
    copyImage({ id });
  };

  const handleReopen = (id) => {
    reopenImage({ id })
      .unwrap()
      .then((response) => handleResponse(
        'success',
        intl,
        'content-image-reopen-success',
        [ () => window.open(response.url) ],
      ))
      .catch((error) => handleResponse('error', intl, 'content-image-reopen-failed', [], error));
  };

  const handlePreviewModalClose = () => {
    togglePreviewModal();
  };

  const actions = (item) => {
    const canClone = HasRights([ Permissions.CONTENT.IMAGES.CLONE ]);
    const canReopen = HasRights([ Permissions.CONTENT.IMAGES.REOPEN ]);
    const canDownload = HasRights([ Permissions.CONTENT.IMAGES.DOWNLOAD ]);

    const actions = [];

    if (canDownload) {
      actions.push(
        <Tooltip key="download" placement="top" title={<IntlMessages id="ui-general-download" />}>
          <DownloadOutlined
            style={{ color: appColors.gray }}
            onClick={() => {
              handleDownload(item.source.data.source_item_id);
            }}
          />
        </Tooltip>,
      );
    }

    if (item.is_copy || item.is_reopened) {
      return actions;
    }

    if (canClone) {
      actions.push(
        <Popconfirm
          key="clone"
          title={<IntlMessages id="content-images-clone-confirm-message" />}
          placement="top"
          onConfirm={() => {
            handleCopy(item.id);
          }}
          okText={<IntlMessages id="ui-general-yes" />}
          cancelText={<IntlMessages id="ui-general-no" />}
          icon={<RiErrorWarningLine className="remix-icon hp-text-color-primary-1" />}
          okButtonProps={{ danger: true }}
        >
          <Tooltip placement="top" title={<IntlMessages id="ui-general-clone" />}>
            <CopyOutlined style={{ color: appColors.mediumGreen }} />
          </Tooltip>
        </Popconfirm>,
      );
    }

    if (canReopen) {
      actions.push(
        <Popconfirm
          key="reopen"
          title={<IntlMessages id="content-images-reopen-confirm-message" />}
          placement="top"
          onConfirm={() => {
            handleReopen(item.id);
          }}
          okText={<IntlMessages id="ui-general-yes" />}
          cancelText={<IntlMessages id="ui-general-no" />}
          icon={<RiErrorWarningLine className="remix-icon hp-text-color-primary-1" />}
          okButtonProps={{ danger: true }}
        >
          <Tooltip placement="top" title={<IntlMessages id="ui-general-reopen" />}>
            <SyncOutlined style={{ color: appColors.orange }} />
          </Tooltip>
        </Popconfirm>,
      );
    }

    return actions;
  };

  const showTotal = (total) => <TotalLabel total={total} />;

  useEffect(() => {
    const queryParams = getQueryParams(window.location.search);

    if (queryParams.search) {
      const initValues = {};

      queryParams.search.split(';').forEach((item) => {
        const [ key, value ] = item.split(':');

        if ([ 'category', 'image_type', 'status', 'tags' ].includes(key) && value?.length) {
          if ([ 'category', 'tags' ].includes(key)) {
            initValues[key] = value.split(',').map((item) => Number(item));
          } else {
            initValues[key] = value.split(',');
          }
        } else if (value !== '') {
          initValues[key] = value;
        }
      });

      setInitFilterValues(initValues);
    }
  }, []);

  useEffect(() => {
    if (!searchParams.toString()) {
      setInitFilterValues({
        reset: true, // ugly hack
      });
    }
  }, [ searchParams ]);

  const isLoading = isFetching || isCopying || isReopening || isDownloading || isCategoriesFetching;

  return (
    <>
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-print-none hp-mb-32"
        align="middle"
      >
        <BreadCrumbs breadCrumbActive={<IntlMessages id='content-images-breadcrumbs' />} />

        <Col>
          <Space>
            <TotalLabel total={dataPagination.total} />
            <ActionButton
              onClick={toggleShowContour}
              title={<IntlMessages id={showContour ? 'content-images-show-colors' : 'content-images-show-contour'} />}
            />
            <ActionButton
              title=""
              icon={<Filter size={18} />}
              onClick={toggleFilterSidebar}
            />
          </Space>
        </Col>

        <Sidebar
          visible={filterOpen}
          toggleSidebar={toggleFilterSidebar}
          width={600}
        >
          <FormImagesFilter
            isSubmitting={false}
            initialValues={initFilterValues}
            onCancel={() => {
              resetFilter({
                searchQueryParams,
                setSearchParams,
                setInitFilterValues,
              });
            }}
            onSubmit={(values) => {
              applyFilter({
                values,
                searchQueryParams,
                searchFilterFields,
                setSearchParams,
                toggleFilterSidebar,
              });
            }}
          />
        </Sidebar>
      </Row>

      <Modal
        width={1200}
        title={intl.formatMessage({ id: 'content-image-preview-card-title' })}
        centered
        visible={previewModalOpen}
        onCancel={handlePreviewModalClose}
        footer={false}
        closeIcon={
          <RiCloseFill className="remix-icon text-color-black-100" size={24} />
        }
      >
        <PreviewImageCard
          initialImageCategoriesValues={initialImageCategoriesValues}
          data={previewImage}
          categories={categories}
          onModalClose={handlePreviewModalClose}
        />
      </Modal>

      {isLoading ? (
        <div className="spin-overlay">
          <div className="spin-wrapper">
            <Spinner spinning={isLoading} />
          </div>
        </div>
      ) : (
        <div>
          {images?.length ? (
            <Row gutter={[ 16, 32 ]}>
              {images.map((item) => {
                return (
                  <Col key={item.id} className='centered-item' xs={24} sm={12} md={8} lg={6} xxl={4}>
                    <ContentCard
                      data={item}
                      showContour={showContour}
                      actions={actions(item)}
                      onClick={handleCardClick}
                      minCardHeight='450px'
                      allowImageClick
                    />
                  </Col>
                );
              })}
              <Col span={24}>
                {dataPagination.total && <Pagination
                  total={dataPagination.total}
                  showTotal={showTotal}
                  pageSize={pagination.limit}
                  current={pagination.page}
                  pageSizeOptions={[ '12', '24', '48', '96' ]}
                  onChange={(page, size) => {
                    handleChangeTableParams({
                      current: page,
                      pageSize: size,
                    }, {}, {});
                  }}
                />}
              </Col>
            </Row>
          ) : (
            <div className='hp-mt-64'>
              <EmptyDataPlaceholder placeholder={<IntlMessages id="ui-general-no-images-found" />} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PageImages;
