import { apiSlice } from '../../../app/api/apiSlice';
import Config from '../../../configs/appConfig';
import makeQueryParams from '../../../lib/makeQueryParams';



export const LogsApiSlice = apiSlice.injectEndpoints({
  tagTypes: [ 'Logs', 'LogsItem' ],
  endpoints: (builder) => {
    return ({
      getLogs: builder.query({
        query: (params) => {
          const queryData = makeQueryParams(params.queryParams);

          return ({ url: `/${Config.VITE_HC_API_VERSION}/logs${queryData}` });
        },
        transformResponse: (response) => {
          return {
            data: response?.data,
            pagination: response?.meta?.pagination,
            customOptions: response?.meta?.custom,
          };
        },
        providesTags: [ 'Logs' ],
      }),
      getSpecificLog: builder.query({
        query: (logId) => ({ url: `/${Config.VITE_HC_API_VERSION}/logs/${logId}` }),
        transformResponse: (response) => response.data,
        providesTags: () => [ { type: 'LogsItem' } ],
      }),
    });
  },
});

export const {
  useGetLogsQuery,
  useGetSpecificLogQuery,
} = LogsApiSlice;
