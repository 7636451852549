import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import range from 'lodash/range';
import { Empty, Skeleton, Tag } from 'antd';
import { Link } from 'react-router-dom';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import { Permissions } from '../../../../const/permissions';
import urlPagePopupsDetails from '../../../../urls/urlPagePopupsDetails';
import { PrivateComponent } from '../../../../components/HasRights/HasRights';
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  EMPTY_IMAGE_PLACEHOLDER,
  EMPTY_VALUE_PLACEHOLDER,
} from '../../../../const/system';



const PopupPriorityList = ({ data, isLoading, onOrderChange, selectedDate }) => {
  const intl = useIntl();
  const [ initOrder, setInitOrder ] = useState([]);
  const [ items, setItems ] = useState(initOrder);


  useEffect(() => {
    if (data.length !== 0) {
      const init = range(1, data.length + 1).map((item) => `${item}`);

      setInitOrder(init);
      setItems(init);
    }
  }, [ data ]);

  const handleDragEnd = (result) => {
    if (!result.destination || result.destination.index === result.source.index) {
      return;
    }

    const initialOrder = [ ...items ];
    const [ reorderedItem ] = initialOrder.splice(result.source.index, 1);

    initialOrder.splice(result.destination.index, 0, reorderedItem);
    setItems(initialOrder);
    onOrderChange(initialOrder);
  };


  const willPublished = (values) => {
    return (
      <div>
        {values?.is_published && (
          <>
            <hr />
            <span className="bold-sm-text">
              {`${intl.formatMessage({ id: 'ui-general-published-at' })}: ${dayjs(values?.published_at).format(DATE_TIME_FORMAT)}`}
            </span>
          </>
        )}
        {!values?.is_published && (
          <Tag className='hp-mt-4'>
            <IntlMessages id="ui-general-not-published" />
          </Tag>
        )}
      </div>
    );
  };

  return (
    <div>
      {isLoading ? <Skeleton /> : (
        <div>
          {!data.length ? (
            <Empty />
          ) : (
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="items" isDropDisabled={dayjs(selectedDate).isBefore(dayjs().subtract(1, 'day').format(DATE_FORMAT))}>
                {({ innerRef, droppableProps, placeholder }) => {
                  return (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                    <div className="news-priority-container" ref={innerRef} {...droppableProps}>
                      {items.map((item, index) => {
                        return (
                          <Draggable draggableId={item} index={index} key={item}>
                            {({ innerRef, draggableProps, dragHandleProps }) => {
                              const position = Number(item) - 1;

                              return (
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                <div className={`hp-d-flex hp-d-flex-between news-priority-item ${!data[position]?.is_published ? 'news-priority-item-off' : ''}`} ref={innerRef} {...draggableProps} {...dragHandleProps}>
                                  <div className="hp-d-flex hp-align-items-center" style={{ width: '100%', overflow: 'hidden' }}>
                                    <LazyLoadImage
                                      style={{ minWidth: '150px' }}
                                      className='news-priority-item__image'
                                      effect='blur'
                                      alt="picture preview"
                                      src={data[position]?.preview ?? EMPTY_IMAGE_PLACEHOLDER}
                                    />
                                    <div className="hp-p-16">
                                      {data[position]?.title ?? EMPTY_VALUE_PLACEHOLDER}
                                      &nbsp;
                                      {`(ID: ${data[position]?.id})`}
                                      <div className="hp-pt-4">
                                        {willPublished(data[position])}
                                      </div>
                                    </div>
                                  </div>

                                  <PrivateComponent allowedPermissions={[ Permissions.OTHER.POPUPS.FIND ]}>
                                    <Link
                                      className='hp-mr-16'
                                      target="_blank"
                                      to={urlPagePopupsDetails({ popupId: data[position]?.id })}
                                    >
                                      <IntlMessages id='ui-general-show' />
                                    </Link>
                                  </PrivateComponent>
                                </div>
                              );
                            }}
                          </Draggable>
                        );
                      })}
                      {placeholder}
                    </div>
                  );
                }}
              </Droppable>
            </DragDropContext>
          )}
        </div>
      )}
    </div>
  );
};

PopupPriorityList.propTypes = {
  data: PropTypes.array.isRequired,
  selectedDate: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onOrderChange: PropTypes.func.isRequired,
};

export default PopupPriorityList;
