import { useIntl } from 'react-intl';



const useCategoriesOptions = (categories = [], addWithoutCategoriesOption = true) => {
  const intl = useIntl();

  const options = addWithoutCategoriesOption
    ? [ {
      label: intl.formatMessage({ id: 'ui-general-without-category' }),
      value: 999999,
    } ] : [];

  return options.concat(
    categories.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    }),
  );
};

export default useCategoriesOptions;
