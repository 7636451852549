import React, { useState } from 'react';
import { Card, Col, Row } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import isObject from 'lodash/isObject';
import urlPageDynamicNews from '../../../../../urls/urlPageDynamicNews';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import handleResponse from '../../../../../lib/handleResponse';
import transformErrors from '../../../../../lib/transformErrors';
import BreadCrumbs from '../../../../../layout/components/breadcrumbs';
import FormCreateDynamicNews from '../../../forms/FormCreateDynamicNews';
import { useCreateDynamicNewsMutation } from '../../../api/dynamicNewsApiSlice';



const PageDynamicNewsCreate = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [ errors, setFormErrors ] = useState({});

  const [ createNews, { isLoading: isCreatingNews } ] = useCreateDynamicNewsMutation();

  const onSubmit = (data) => {
    createNews(data)
      .unwrap()
      .then(() => {
        handleResponse(
          'success',
          intl,
          'ui-general-created-successfully',
          [
            () => navigate(urlPageDynamicNews()),
          ],
        );
      })
      .catch((error) => {
        handleResponse(
          'error',
          intl,
          'ui-general-create-failed',
          [
            () => {
              if (isObject(error?.data?.errors)) {
                const formikInitialErrors = transformErrors(error?.data?.errors);

                setFormErrors(formikInitialErrors);
              }
            },
          ],
          error,
        );
      });
  };

  return (
    <>
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-print-none hp-mb-32"
        align="middle"
      >
        <BreadCrumbs
          breadCrumbParent={
            <Link to={urlPageDynamicNews()}>
              <IntlMessages id='dynamic-news-breadcrumbs' />
            </Link>
          }
          breadCrumbActive={<IntlMessages id='dynamic-news-create-breadcrumbs' />}
        />
      </Row>

      <Row gutter={[ 32, 32 ]}>
        <Col span={24}>
          <Card className="hp-border-color-black-40 hp-card-6">
            <FormCreateDynamicNews
              initialErrors={errors}
              isSubmitting={isCreatingNews}
              onSubmit={onSubmit}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PageDynamicNewsCreate;
