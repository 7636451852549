import React from 'react';
import { Form, FormItem, Select, SubmitButton } from 'formik-antd';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'antd';
import Yup from '../../../../vendor/yup';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import makeSelectFilterOption from '../../../../lib/makeSelectFilterOption';
import makeSelectFilterSort from '../../../../lib/makeSelectFilterSort';



const { Option } = Select;


const validationSchema = Yup.object().shape({
  permissions_ids: Yup.array().nullable(),
});


const FormEditUserPermissions = ({
  onSubmit,
  onCancel,
  initialValues,
  formErrors,
  isSubmitting,
  permissionsList,
}) => {
  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      isSubmitting={isSubmitting}
      initialValues={initialValues}
      initialErrors={formErrors}
      onSubmit={onSubmit}
    >
      {(props) => {
        // eslint-disable-next-line react/prop-types
        const { values, isValid, dirty } = props;

        return (
          <Form layout="vertical" className='w-full'>
            <FormItem
              name='permissions_ids'
              label={<IntlMessages id="ui-general-permissions" />}
            >
              <Select
                mode="multiple"
                allowClear
                showSearch
                name="permissions_ids"
                className='w-full'
                placeholder={<IntlMessages id='form-create-user-permissions-placeholder' />}
                value={values.permissions_ids}
                optionFilterProp="children"
                filterOption={(input, option) => makeSelectFilterOption(option.children, input)}
                filterSort={(optionA, optionB) => makeSelectFilterSort(optionA, optionB)}
              >
                {permissionsList.map((permission) => (
                  <Option key={permission.id} value={permission.id}>
                    {permission.display_name ?? permission.description}
                  </Option>
                ))}
              </Select>
            </FormItem>

            <Row gutter={[ 16, 16 ]} className='centered-item'>
              <Col>
                <Button
                  type='secondary'
                  onClick={onCancel}
                >
                  <IntlMessages id='ui-general-cancel' />
                </Button>
              </Col>

              <Col>
                <SubmitButton
                  loading={isSubmitting}
                  disabled={!isValid || !dirty}
                >
                  <IntlMessages id='ui-general-submit' />
                </SubmitButton>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

FormEditUserPermissions.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  formErrors: PropTypes.object,
  values: PropTypes.shape({
    permissions_ids: PropTypes.array,
  }),
  permissionsList: PropTypes.array,
  isSubmitting: PropTypes.bool.isRequired,
};

FormEditUserPermissions.defaultProps = {
  formErrors: {},
  values: {
    permissions_ids: [],
  },
  permissionsList: [],
};

export default FormEditUserPermissions;
