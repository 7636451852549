const appColors = {
  white: '#ffffff',
  darkGray: '#1e1e1e',
  lightGray: '#1e1e1e29',
  gray: '#626262',
  smokeGray: '#e2eaf6',
  lightIndigo: '#7367f0',
  orange: '#ff9f43',
  mediumGreen: '#28c76f',
  brightCyan: '#00cfe8',
  lightRed: '#ff18186e',
  blueLink: '#319ebde0',
  success: '#00F7BF',
  info: '#1BE7FF',
  warning: '#FFC700',
  error: '#FF0022',
};

export default appColors;
