import { EMPTY_VALUE_PLACEHOLDER } from '../../../const/system';
import { FEATURES_CONTENT_TYPE_MAP } from '../pages/PageFeatures/PageFeatures.const';
import { NEWS_DICT_STRUCTURE } from '../../Content/pages/News/PageDynamicNews/PageDynamicNews.const';
import { POPUPS_SOURCE } from '../../Other/pages/Popups/PagePopups/PagePopups.const';
import { COLLECTIONS_SOURCE } from '../../Content/pages/Collections/PageCollections/PageCollections.const';



const getFeatureSourceTranslation = (source) => {
  switch (source) {
    case FEATURES_CONTENT_TYPE_MAP.DAILY: return 'features-breadcrumbs-daily';
    case FEATURES_CONTENT_TYPE_MAP.EDITOR_CHOICE: return 'features-breadcrumbs-editors-choice';
    case FEATURES_CONTENT_TYPE_MAP.EXTERNAL_PROMO: return 'features-breadcrumbs-external-promo';
    case NEWS_DICT_STRUCTURE: return 'dynamic-news-breadcrumbs';
    case POPUPS_SOURCE: return 'popups-breadcrumbs';
    case COLLECTIONS_SOURCE: return 'collections-breadcrumbs';

    default: return EMPTY_VALUE_PLACEHOLDER;
  }
};

export default getFeatureSourceTranslation;
