const content = {
  'content-images-breadcrumbs': 'Картинки',
  'content-image-colors-amount': 'Кол-во цветов',
  'content-image-taps': 'Кол-во тапов',
  'content-images-folder-name-placeholder': 'Введите Bee ID',
  'content-images-status-placeholder': 'Выберите статус(ы)',
  'content-images-taps-from-label': 'Тапы с',
  'content-images-taps-to-label': 'Тапы по',
  'content-images-colors-from-label': 'Цвета от',
  'content-images-colors-to-label': 'Цвета по',
  'content-images-imported-from-label': 'Импортированы от',
  'content-images-imported-to-label': 'Импортированы по',
  'content-images-released-from-label': 'Опубликованы от',
  'content-images-category-label': 'Категория',
  'content-images-category-client-id': 'ID клиента',
  'content-images-category-client-placeholder': 'Укаджите ID клиента',
  'content-images-category-deleted-at': 'Удалено в',
  'content-images-main-category-label': 'Только главная категория',
  'content-images-tags-label': 'Теги',
  'content-images-image-type-label': 'Тип картинки',
  'content-images-image-type-placeholder': 'Выберите тип картинки',
  'content-images-tag-placeholder': 'Выберите теги',
  'content-image-preview-card-title': 'Предпросмотр картинки',
  'content-image-preview-taps-label': 'Тапы / Цвета',
  'content-images-clone-confirm-message': 'Вы уверены, что хотите клонировать картинку?',
  'content-images-reopen-confirm-message': 'Вы уверены, что хотите переоткрыть картинку?',
  'content-image-reopen-success': 'Картинка переоткрыта успешно',
  'content-image-reopen-failed': 'Ошибка при переоткрытии картинки',
  'content-image-filter-loading': 'Фильтр загружается...',
  'content-images-show-contour': 'Показать линии',
  'content-images-show-colors': 'Показать цвета',
  'content-images-main-category': 'Основная категория',
  'content-images-main-category-placeholder': 'Выберите основную категорию',
};

export default content;
