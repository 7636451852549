import React from 'react';
import { Form, FormItem, Input, Select, ResetButton, SubmitButton } from 'formik-antd';
import { Formik } from 'formik';
import { Col, DatePicker, Row, Tooltip } from 'antd';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';
import { InfoCircleOutlined } from '@ant-design/icons';
import isEmpty from 'lodash/isEmpty';
import upperFirst from 'lodash/upperFirst';
import { DATE_FORMAT } from '../../../../const/system';
import { validationSchema } from './FormLogsFilter.const';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import useDatepickerHandler from '../../../../app/hooks/useDatepickerHandler';
import makeSelectFilterOption from '../../../../lib/makeSelectFilterOption';
import makeSelectFilterSort from '../../../../lib/makeSelectFilterSort';
import getNotEmptyObjectValues from '../../../../lib/getNotEmptyObjectValues';
import Spinner from '../../../../components/Spinner';



const { Option } = Select;

const FormLogsFilter = ({
  initialValues,
  onSubmit,
  onCancel,
  isSubmitting,
  optionsData,
}) => {
  const intl = useIntl();
  const { onBlur } = useDatepickerHandler();

  return (
    <Formik
      enableReinitialize
      isSubmitting
      validationSchema={validationSchema}
      initialValues={initialValues}
      onReset={onCancel}
      onSubmit={async (values) => {
        await onSubmit(getNotEmptyObjectValues(values));
      }}
    >
      {({ isValid, dirty, values, setFieldValue, setFieldTouched }) => {
        return (
          <Form layout="vertical">
            <Spinner spinning={isSubmitting}>
              <Row gutter={16}>
                <Col span={24}>
                  <FormItem
                    name='id'
                    className="ant-form-item-col stretched"
                    label={
                      <Row className='w-full' justify="space-between" align="middle">
                        <IntlMessages id="ui-general-id" />
                        <Tooltip placement="topRight" title={<IntlMessages id="ui-general-filter-id-key-help" />}>
                          <InfoCircleOutlined />
                        </Tooltip>
                      </Row>
                    }
                  >
                    <Input
                      name="id"
                      placeholder={intl.formatMessage({ id: 'ui-general-ids-placeholder' })}
                    />
                  </FormItem>
                </Col>

                <Col span={24}>
                  <FormItem
                    className='ant-form-item-col'
                    name="user_id"
                    label={intl.formatMessage({ id: 'ui-general-user' })}
                  >
                    <Select
                      allowClear
                      showSearch
                      name="user_id"
                      value={values?.user_id}
                      placeholder={intl.formatMessage({ id: 'logs-form-user-placeholder' })}
                      optionFilterProp="children"
                      filterOption={(input, option) => makeSelectFilterOption(option.children, input)}
                      filterSort={(optionA, optionB) => makeSelectFilterSort(optionA, optionB)}
                    >
                      {!isEmpty(optionsData?.users) ? optionsData.users.map((item) => {
                        return (
                          <Option key={item.id} value={String(item.id)}>{item.name}</Option>
                        );
                      }) : null}
                    </Select>
                  </FormItem>
                </Col>

                <Col span={24}>
                  <FormItem
                    className='ant-form-item-col'
                    name="content_type"
                    label={intl.formatMessage({ id: 'ui-general-content-type' })}
                  >
                    <Select
                      allowClear
                      showSearch
                      name="content_type"
                      value={values?.content_type}
                      placeholder={intl.formatMessage({ id: 'ui-general-content-type-placeholder' })}
                      optionFilterProp="children"
                      filterOption={(input, option) => makeSelectFilterOption(option.children, input)}
                      filterSort={(optionA, optionB) => makeSelectFilterSort(optionA, optionB)}
                    >
                      {!isEmpty(optionsData?.content_types) ? optionsData.content_types.map((item) => {
                        return (
                          <Option key={item} value={item}>{upperFirst(item)}</Option>
                        );
                      }) : null}
                    </Select>
                  </FormItem>
                </Col>

                <Col span={24}>
                  <FormItem
                    className='ant-form-item-col'
                    name="event"
                    label={intl.formatMessage({ id: 'logs-table-event-col' })}
                  >
                    <Select
                      allowClear
                      showSearch
                      name="event"
                      value={values?.event}
                      placeholder={intl.formatMessage({ id: 'logs-form-event-placeholder' })}
                      optionFilterProp="children"
                      filterOption={(input, option) => makeSelectFilterOption(option.children, input)}
                      filterSort={(optionA, optionB) => makeSelectFilterSort(optionA, optionB)}
                    >
                      {!isEmpty(optionsData?.events) ? optionsData.events.map((item) => {
                        return (
                          <Option key={item} value={item}>{upperFirst(item)}</Option>
                        );
                      }) : null}
                    </Select>
                  </FormItem>
                </Col>
              </Row>

              <Row gutter={[ 16, 16 ]}>
                <Col span={12}>
                  <FormItem
                    label={intl.formatMessage({ id: 'ui-general-created-from' })}
                    name='created_from'
                  >
                    <DatePicker
                      name='created_from'
                      format={DATE_FORMAT}
                      showToday
                      value={values?.created_from ? moment(values.created_from) : null}
                      onChange={async (date, dateString) => {
                        await setFieldValue('created_from', dateString);
                      }}
                      onBlur={async ({ target }) => {
                        await setFieldTouched('created_from', true);
                        await onBlur('created_from', target.value, setFieldValue);
                      }}
                    />
                  </FormItem>
                </Col>

                <Col span={12}>
                  <FormItem
                    label={intl.formatMessage({ id: 'ui-general-created-to' })}
                    name='created_to'
                  >
                    <DatePicker
                      name='created_to'
                      format={DATE_FORMAT}
                      value={values?.created_to ? moment(values.created_to) : null}
                      onChange={async (date, dateString) => {
                        await setFieldValue('created_to', dateString);
                      }}
                      onBlur={async ({ target }) => {
                        await setFieldTouched('created_to', true);
                        await onBlur('created_to', target.value, setFieldValue);
                      }}
                    />
                  </FormItem>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <FormItem
                    className='ant-form-item-col'
                    name="message"
                    label={intl.formatMessage({ id: 'logs-table-message-col' })}
                  >
                    <Input.TextArea
                      name='message'
                      label={intl.formatMessage({ id: 'logs-form-message-placeholder' })}
                      rows={4}
                      showCount
                      maxLength={191}
                    />
                  </FormItem>
                </Col>
              </Row>

              <Row gutter={[ 16, 16 ]} justify='end'>
                <Col>
                  <ResetButton disabled={false} loading={isSubmitting}>
                    <IntlMessages id='ui-general-reset' />
                  </ResetButton>
                </Col>

                <Col>
                  <SubmitButton
                    loading={isSubmitting}
                    disabled={!isValid || !dirty}
                  >
                    <IntlMessages id="ui-general-apply" />
                  </SubmitButton>
                </Col>
              </Row>
            </Spinner>
          </Form>
        );
      }}
    </Formik>
  );
};

FormLogsFilter.propTypes = {
  initialValues: PropTypes.shape({}).isRequired,
  onSubmit: PropTypes.func.isRequired,
  optionsData: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default FormLogsFilter;
