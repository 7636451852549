import React from 'react';
import { Tooltip } from 'antd';
import { EMPTY_VALUE_PLACEHOLDER } from "../../const/system";



const makeCollapsedText = (item, maxLength) => {
  if (item && item.length > maxLength) {
    return item.slice(0, maxLength) + '...';
  }
  return item ?? EMPTY_VALUE_PLACEHOLDER;
};

const CollapsibleText = ({ text, maxLength = 40 }) => {
  const content = makeCollapsedText(text, maxLength);

  return (
    <>
    {text && text.length > maxLength ? (
      <Tooltip title={text}>
        <span className='hp-cursor-pointer'>
          {content}
        </span>
      </Tooltip>
    ) : <span>{content}</span>}
    </>
  )};

export default CollapsibleText;
