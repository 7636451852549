import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'antd';



const PreviewImages = ({ icon, preview, fullWidth = false }) => {
  return (
    <>
      <Col xs={24} md={fullWidth ? 10 : 7} xxl={fullWidth ? 11 : 7}>
        <img className="preview-image" alt="icon" src={icon} />
      </Col>
      <Col xs={24} md={fullWidth ? 10 : 7} xxl={fullWidth ? 11 : 7}>
        <img className="preview-image" alt="preview" src={preview} />
      </Col>
    </>
  );
};

PreviewImages.propTypes = {
  icon: PropTypes.string.isRequired,
  preview: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
};

export default PreviewImages;
