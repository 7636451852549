import React, { useEffect, useState } from 'react';
import { Form, FormItem, Input, Select, SubmitButton } from 'formik-antd';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Col, Row } from 'antd';
import { useIntl } from 'react-intl';
import { RiArrowLeftLine } from 'react-icons/ri';
import Yup from '../../../../vendor/yup';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import urlPageDictionaries from '../../../../urls/urlPageDictionaries';
import { useDictionaries, useDictionariesDispatch } from '../../providers/DictionariesProvider';
import makeSelectFilterOption from '../../../../lib/makeSelectFilterOption';



const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(4, 'Min 4 symbols required')
    .max(50, 'Max 50 symbols required')
    .required('Name field is required'),
  source: Yup.string(),
  structure_id: Yup.string()
    .required('Structure field is required'),
});

const FormCreateDictionary = ({
  onSubmit,
  isSubmitting,
  formErrors,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [ searchQueryParams ] = useSearchParams();
  const { sources, structures } = useDictionaries();
  const [ initialValues, setInitialValues ] = useState({});
  const dispatch = useDictionariesDispatch();

  const navigationSource = searchQueryParams.get('source') ?? null;
  const multipleSources = searchQueryParams.get('multipleSources') ?? null;
  const defaultSource = sources?.data?.find((item) => item.source === navigationSource);

  const filteredSources = multipleSources ?
    {
      'data': sources.data,
      'options': sources.options.filter((source) => multipleSources.split(',').map((item) => intl.formatMessage({ id: `structures-source-${item.toLowerCase()}` })).includes(source.label)),
    } : sources;

  useEffect(() => {
    if (defaultSource && !multipleSources) {
      setInitialValues((original) => {
        return { ...original, source: defaultSource.id };
      });

      dispatch({
        type: 'SET_STRUCTURE',
        structureId: null,
      });
      dispatch({
        type: 'SET_SOURCE',
        source: defaultSource.id,
      });
    }
  }, [ defaultSource ]);

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      isSubmitting={isSubmitting}
      initialValues={initialValues}
      initialErrors={formErrors}
      onSubmit={(values) => {
        onSubmit({
          name: values.name.trim(),
          structure_id: values.structure_id,
        });
      }}
    >
      {(props) => {
        // eslint-disable-next-line react/prop-types
        const { isValid, setFieldValue, dirty } = props;

        return (
          <Form layout="vertical" className='w-full'>
            <FormItem
              className='ant-form-item-col'
              name="name"
              label={<IntlMessages id="ui-general-name" />}
              required
            >
              <Input
                name='name'
                placeholder={intl.formatMessage({ id: 'ui-general-name' })}
              />
            </FormItem>

            <FormItem
              className='ant-form-item-col'
              name="code"
              label={<IntlMessages id="form-dictionaries-source-field" />}
              required
            >
              <Select
                showSearch
                name="source"
                disabled={defaultSource && !multipleSources}
                loading={sources.isLoading}
                options={filteredSources.options}
                placeholder={<IntlMessages id="form-dictionaries-source-placeholder" />}
                filterOption={(input, option) => makeSelectFilterOption(option.label, input)}
                onChange={(value) => {
                  dispatch({
                    type: 'SET_STRUCTURE',
                    structureId: null,
                  });
                  setFieldValue('structure_id', null);
                  dispatch({
                    type: 'SET_SOURCE',
                    source: value,
                  });
                }}
              />
            </FormItem>

            <FormItem
              className='ant-form-item-col'
              name="code"
              label={<IntlMessages id="form-dictionaries-structure-field" />}
              required
            >
              <Select
                name="structure_id"
                loading={structures.isLoading}
                options={structures.options}
                placeholder={<IntlMessages id="form-dictionaries-structure-placeholder" />}
                onChange={(value) => {
                  dispatch({
                    type: 'SET_STRUCTURE',
                    structureId: value,
                  });
                  setFieldValue('structure_id', value);
                }}
              />
            </FormItem>

            <Row gutter={[ 16, 16 ]} justify='end'>
              <Col>
                <Button
                  icon={<RiArrowLeftLine className="hp-mr-8" size={18} />}
                  onClick={() => navigate(urlPageDictionaries())}
                >
                  <IntlMessages id='ui-general-back-to-list' />
                </Button>
              </Col>
              <Col>
                <SubmitButton
                  loading={isSubmitting}
                  disabled={!isValid || !dirty}
                >
                  <IntlMessages id='ui-general-submit' />
                </SubmitButton>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

FormCreateDictionary.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  formErrors: PropTypes.object,
  isSubmitting: PropTypes.bool.isRequired,
};

FormCreateDictionary.defaultProps = {
  formErrors: {},
};

export default FormCreateDictionary;
