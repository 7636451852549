import React, { useState } from 'react';
import { Card, Col, Input, Modal, Row, Table } from 'antd';
import { RiAddLine, RiCloseFill } from 'react-icons/ri';
import { Search } from 'react-iconly';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import Spinner from '../../../../../components/Spinner';
import BreadCrumbs from '../../../../../layout/components/breadcrumbs';
import ActionButton from '../../../../../layout/components/action-button';
import { getRolesListTableColumns } from './PageRoles.const';
import FormCreateEditRole from '../../../forms/FormCreateEditRole';
import handleResponse from '../../../../../lib/handleResponse';
import { useCreateRoleMutation, useDeleteRoleMutation, useGetRolesQuery } from '../../../api/rolesApiSlice';
import useToggle from '../../../../../app/hooks/useToggle';
import useElementSize from '../../../../../app/hooks/useElementSize';
import useQueryParams from '../../../../../app/hooks/useQueryParams';
import makeTableLoadingObject from '../../../../../lib/makeTableLoadingObject';
import { Permissions } from '../../../../../const/permissions';
import { HasRights } from '../../../../../components/HasRights/HasRights';
import basePagination from '../../../../../const/pagination';



const PageRoles = () => {
  const intl = useIntl();
  const [ elementRef, size ] = useElementSize();
  const [ isActiveRoleModal, toggleCreateRoleModal ] = useToggle();
  const [ createRoleErrors, setCreateRoleErrors ] = useState({});

  const {
    pagination,
    search,
    setSearchTerm,
    handleChangeTableParams,
    searchParams,
  } = useQueryParams({ searchFields: [ 'name', 'display_name' ] });


  const {
    data: rolesList = { roles: [], pagination: {} },
    isLoading,
  } = useGetRolesQuery({
    queryParams: searchParams.toString(),
  });
  const [ deleteRole, { isLoading: isLoadingDeleteRole } ] = useDeleteRoleMutation();
  const [ createRole, { isLoading: isLoadingCreateRole } ] = useCreateRoleMutation();

  const handleDeleteRole = (roleId) => {
    deleteRole(roleId)
      .unwrap()
      .then(() => handleResponse('success', intl, 'ui-general-deleted-successfully'))
      .catch((error) => handleResponse('error', intl, 'ui-general-delete-failed', [], error));
  };

  const handleSubmitCreateRoleForm = async (values) => {
    createRole(values)
      .unwrap()
      .then(() => handleResponse(
        'success',
        intl,
        'ui-general-created-successfully',
        [
          toggleCreateRoleModal,
        ],
      ))
      .catch((error) => handleResponse(
        'error',
        intl,
        'ui-general-create-failed',
        [
          () => {
            if (isObject(error?.data?.errors)) {
              setCreateRoleErrors(error.data.errors);
            }
          },
        ],
        error,
      ));
  };

  const onCancelCreateRoleModal = () => {
    toggleCreateRoleModal();
    setCreateRoleErrors({});
  };

  const handleSearch = (ev) => {
    setSearchTerm(ev.target.value);
  };

  return (
    <>
      <Modal
        title={<IntlMessages id="roles-create-role-title" />}
        width={416}
        centered
        destroyOnClose
        visible={isActiveRoleModal}
        onCancel={onCancelCreateRoleModal}
        footer={null}
        closeIcon={
          <RiCloseFill className="remix-icon text-color-black-100" size={24} />
        }
      >
        <Spinner spinning={isLoadingCreateRole}>
          <FormCreateEditRole
            initialValues={{}}
            formErrors={createRoleErrors}
            onSubmit={handleSubmitCreateRoleForm}
            onCancel={onCancelCreateRoleModal}
            isSubmitting={isLoadingCreateRole}
          />
        </Spinner>
      </Modal>

      <Row gutter={32}>
        <Col className="hp-mb-32" span={24}>
          <Row
            gutter={[ 32, 32 ]}
            justify="space-between"
            className="hp-print-none"
          >
            <BreadCrumbs breadCrumbActive={<IntlMessages id='roles-breadcrumbs' />} />

            <Col md={15} span={24}>
              <Row justify="end" align="middle" gutter={[ 16, 16 ]}>
                <Col md={14} span={24}>
                  <Input
                    allowClear={!isEmpty(search)}
                    placeholder={intl.formatMessage({ id: 'roles-search-by' })}
                    prefix={<Search set="curved" size={16} className="hp-text-color-black-80" />}
                    value={search}
                    onChange={handleSearch}
                  />
                </Col>

                {HasRights([ Permissions.ADMINISTRATION.ROLES.ROLES_LIST.CREATE_ROLE ]) && (
                  <Col>
                    <ActionButton
                      title={<IntlMessages id="roles-create-role-title" />}
                      icon={<RiAddLine className="btn-icon-mr-1" />}
                      onClick={toggleCreateRoleModal}
                    />
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Card className="hp-border-color-black-40">
            <div ref={elementRef}>
              <Table
                sticky
                scroll={{ x: 860 }}
                loading={makeTableLoadingObject(isLoading || isLoadingDeleteRole || isLoadingCreateRole)}
                rowKey="id"
                columns={getRolesListTableColumns(handleDeleteRole, size)}
                dataSource={rolesList.roles}
                onChange={handleChangeTableParams}
                pagination={{
                  ...basePagination,
                  current: pagination.page,
                  pageSize: pagination.limit,
                  total: rolesList.pagination?.total,
                }}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};


export default PageRoles;
