const categories = {
  'categories-breadcrumbs': 'Категории',
  'categories-create-successfully': 'Категория создана',
  'categories-update-successfully': 'Категория обновлена',
  'categories-create-failed': 'Ошибка при создании категории',
  'categories-update-failed': 'Ошибка при обновлении категории',
  'categories-create-form-field-client-id-help': 'Уникальный ID для "core" клиента для распознания категории',
  'categories-create-form-field-name-placeholder': 'Введите название',
  'categories-create-form-field-description-placeholder': 'Введите описание',
  'categories-create-form-field-options': 'Доп. опции',
  'categories-create-form-field-options-placeholder': 'Введите доп. опции',
  'categories-create-form-field-options-help': 'Вы можете задать дополнительные параметры, такие как конфигурация таргета (min_app_version, core_version и пр.) в JSON формате',
  'categories-create-form-field-dictionary-placeholder': 'Выберите словарь',
  'categories-view-images': 'Изображения',
  'categories-view-images-empty': 'Нет изображений',
  'categories-priorities': 'Приоритеты',
  'categories-priorities-title': 'Перетащите категории чтобы установить нужный приоритет',
  'categories-priorities-empty': 'Категории отсутствуют',
  'categories-priorities-disabled-action': 'Перетащите категории для последующего обновления приоритетов',
};

export default categories;
