import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Space } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import isObject from 'lodash/isObject';
import { MdOutlineUnpublished } from 'react-icons/md';
import { RiArrowLeftLine } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import Yup from '../../../../../vendor/yup';
import urlPageDynamicNews from '../../../../../urls/urlPageDynamicNews';
import urlPageDynamicNewsDetails from '../../../../../urls/urlPageDynamicNewsDetails';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import { TYPE_IMAGE, TYPE_OBJECT } from '../../../../Template builder/const/templates';
import {
  fieldTypeToValidationRule,
  mkValidationSchema,
} from '../../../forms/FormCreateDynamicNews/FormCreateDynamicNews.const';
import BreadCrumbs from '../../../../../layout/components/breadcrumbs';
import FormEditDynamicNews from '../../../forms/FormEditDynamicNews';
import isFieldRequired from '../../../utils/isFieldRequired';
import createFileFromURL from '../../../../../lib/createFileFromURL';
import getFullFieldName from '../../../utils/getFullFieldName';
import transformErrors from '../../../../../lib/transformErrors';
import ActionButton from '../../../../../layout/components/action-button';
import useModifiedDynamicDataErrors from '../../../../../app/hooks/useModifiedDynamicDataErrors';
import handleResponse from '../../../../../lib/handleResponse';
import {
  useGetSpecificDynamicNewsQuery,
  useUnpublishDynamicNewsMutation,
  useUpdateDynamicNewsMutation,
} from '../../../api/dynamicNewsApiSlice';



let validation = {};

const PageDynamicNewsEdit = () => {
  const intl = useIntl();
  const language = useSelector(({ customise: { language } }) => language);
  const { newsId } = useParams();
  const navigate = useNavigate();
  const [ initValues, setInitValues ] = useState({ preset_id: null, data: {} });
  const [ errors, setFormErrors ] = useState({});

  const { data: dynamicNews = {}, isFetching } = useGetSpecificDynamicNewsQuery(newsId);
  const [ updateNews, { isLoading: isUpdatingNews } ] = useUpdateDynamicNewsMutation();
  const [ unpublish, { isLoading: isUnpublishDynamicNewsLoading } ] = useUnpublishDynamicNewsMutation();

  const modifiedErrors = useModifiedDynamicDataErrors(
    dynamicNews?.template?.data.id,
    dynamicNews?.template?.data,
    errors,
    language,
  );

  const getDynamicData = (initialValues, detailsData) => {
    const data = {};
    const dataSchema = {};

    detailsData?.template?.data?.fields?.data.filter((field) => field.type !== TYPE_OBJECT)
      .forEach(async (field) => {
        const newsField = detailsData?.dynamic_data?.data?.find((item) => item.template_field_id === field.id);
        const fieldName = getFullFieldName(field);

        if (isFieldRequired(field?.validations?.data || [])) {
          dataSchema[fieldName] = Yup.object().shape({
            template_field_id: Yup.number().required(),
            ...fieldTypeToValidationRule[field.type],
          });
        }

        if (field.type === TYPE_IMAGE) {
          if (newsField?.value) {
            const fileName = newsField.value?.split('/').pop();
            const file = await createFileFromURL(newsField?.value, fileName);

            data[fieldName] = {
              value: [ {
                uid: '-1',
                name: newsField?.value,
                thumbUrl: newsField?.value,
                status: 'done',
                originFileObj: file,
                url: newsField?.value,
              } ],
              template_field_id: field.id,
            };
          } else {
            data[fieldName] = {
              value: [],
              template_field_id: field.id,
            };
          }
        } else {
          data[fieldName] = {
            value: newsField?.value,
            template_field_id: field.id,
          };
        }

        setInitValues((prevState) => ({
          ...prevState,
          data,
        }));
      });

    validation = mkValidationSchema(
      initialValues,
      { data: Yup.object().shape(dataSchema) },
    );


    return data;
  };

  useEffect(() => {
    const initialValues = {
      title: dynamicNews?.title,
      is_published: false,
      released_at: dynamicNews?.released_at,
      released_to: dynamicNews?.released_to,
      updated_at: dynamicNews?.updated_at,
      published_at: dynamicNews?.published_at,
      template_id: dynamicNews?.template?.data?.id,
      preset_id: null,
      target: {
        min_version: dynamicNews?.target?.data?.min_version ?? '',
        max_version: dynamicNews?.target?.data?.max_version ?? '',
        platform: dynamicNews?.target?.data?.platform,
        countries: [ ...dynamicNews?.target?.data.countries ?? [] ].sort(),
      },
      data: {},
    };

    setInitValues(initialValues);
    getDynamicData(initialValues, dynamicNews);

  }, [ isFetching ]);

  const handleUnpublish = () => {
    unpublish(newsId)
      .unwrap()
      .then(() => handleResponse('success', intl, 'ui-general-unpublished-successfully'))
      .catch((error) => handleResponse('error', intl, 'ui-general-unpublish-failed', [], error));
  };

  const onSubmit = (data) => {
    updateNews({ id: newsId, values: data })
      .unwrap()
      .then(() => handleResponse(
        'success',
        intl,
        'ui-general-updated-successfully',
        [
          () => navigate(urlPageDynamicNews()),
        ],
      ))
      .catch((error) => handleResponse(
        'error',
        intl,
        'ui-general-update-failed',
        [
          () => {
            if (isObject(error?.data?.errors)) {
              setFormErrors(transformErrors(error?.data?.errors));
            }
          },
        ],
        error,
      ));
  };

  return (
    <>
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-print-none hp-mb-32"
        align="middle"
      >
        <BreadCrumbs
          breadCrumbParent={
            <Link to={urlPageDynamicNews()}>
              <IntlMessages id='dynamic-news-breadcrumbs' />
            </Link>
          }
          breadCrumbParent2={
            <Link to={urlPageDynamicNewsDetails({ newsId })}>
              {newsId}
            </Link>
          }
          breadCrumbActive={<IntlMessages id='dynamic-news-update-breadcrumbs' />}
        />

        <Col md={12} span={24}>
          <Row
            gutter={[ 8, 8 ]}
            justify="end"
            align="middle"
          >
            {Boolean(dynamicNews?.is_published) && (
              <Col>
                <Space>
                  <ActionButton
                    loading={isUnpublishDynamicNewsLoading}
                    title={intl.formatMessage({ id: 'ui-general-unpublish' })}
                    icon={<MdOutlineUnpublished className="hp-mr-8" size={18} />}
                    onClick={handleUnpublish}
                  />
                </Space>
              </Col>
            )}
            <Col>
              <Space>
                <Link to={urlPageDynamicNews()}>
                  <Button
                    type="primary"
                    className="hp-mr-sm-8"
                    ghost="true"
                    icon={<RiArrowLeftLine className="hp-mr-8" size={18} />}
                  >
                    <IntlMessages id='ui-general-back-to-list' />
                  </Button>
                </Link>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={[ 32, 32 ]}>
        <Col span={24}>
          <Card className="hp-border-color-black-40 hp-card-6">
            <FormEditDynamicNews
              initialErrors={modifiedErrors}
              initialValues={initValues}
              templateFields={dynamicNews?.template?.data?.fields?.data || []}
              templateOptions={[ {
                label: dynamicNews?.template?.data?.title,
                value: dynamicNews?.template?.data?.id,
              } ]}
              validationSchema={validation}
              isSubmitting={isFetching || isUpdatingNews || isUnpublishDynamicNewsLoading}
              onCancel={() => {}}
              onSubmit={onSubmit}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PageDynamicNewsEdit;
