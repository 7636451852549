import React from 'react';
import { Form, FormItem, Input, ResetButton, SubmitButton } from 'formik-antd';
import { Formik } from 'formik';
import { Col, DatePicker, Row } from 'antd';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import makePreventOnKeyDown from '../../../../lib/makePreventOnKeyDown';
import Spinner from '../../../../components/Spinner';



const FormAchievementsFilter = ({
  initialValues,
  onSubmit,
  onCancel,
  isSubmitting,
}) => {
  const intl = useIntl();

  return (
    <Formik
      enableReinitialize
      isSubmitting
      initialValues={initialValues}
      onSubmit={onSubmit}
      onReset={onCancel}
    >
      {({ isValid, dirty, values, setFieldValue }) => {
        return (
          <Form
            layout="vertical"
            className="images-filter w-full"
            onKeyDown={makePreventOnKeyDown}
          >
            <Spinner spinning={isSubmitting}>
              <Row gutter={16}>
                <Col span={12}>
                  <FormItem
                    label={intl.formatMessage({ id: 'ui-general-id' })}
                    name='id'
                  >
                    <Input
                      type="number"
                      name="id"
                      placeholder={intl.formatMessage({ id: 'ui-general-id-placeholder' })}
                    />
                  </FormItem>
                </Col>

                <Col span={12}>
                  <FormItem
                    label={intl.formatMessage({ id: 'ui-general-code' })}
                    name='code'
                  >
                    <Input
                      name="code"
                      placeholder={intl.formatMessage({ id: 'ui-general-specify-code' })}
                    />
                  </FormItem>
                </Col>

                <Col span={24}>
                  <FormItem
                    label={intl.formatMessage({ id: 'ui-general-name' })}
                    name='name'
                  >
                    <Input
                      name="name"
                      placeholder={intl.formatMessage({ id: 'ui-general-specify-name' })}
                    />
                  </FormItem>
                </Col>


                <Col span={12}>
                  <FormItem
                    label={intl.formatMessage({ id: 'ui-general-created-from' })}
                    name='created_from'
                  >
                    <DatePicker
                      name="created_from"
                      value={values?.created_from ? moment(values.created_from) : null}
                      onChange={(date, dateString) => {
                        setFieldValue('created_from', dateString);
                      }}
                    />
                  </FormItem>
                </Col>

                <Col span={12}>
                  <FormItem
                    label={intl.formatMessage({ id: 'ui-general-created-to' })}
                    name='created_to'
                  >
                    <DatePicker
                      name="created_to"
                      value={values?.created_to ? moment(values.created_to) : null}
                      onChange={(date, dateString) => {
                        setFieldValue('created_to', dateString);
                      }}
                    />
                  </FormItem>
                </Col>
              </Row>

              <Row gutter={[ 16, 16 ]} justify='end'>
                <Col>
                  <ResetButton disabled={false} loading={isSubmitting}>
                    <IntlMessages id='ui-general-reset' />
                  </ResetButton>
                </Col>

                <Col>
                  <SubmitButton
                    loading={isSubmitting}
                    disabled={!isValid || !dirty}
                  >
                    <IntlMessages id="ui-general-apply" />
                  </SubmitButton>
                </Col>
              </Row>
            </Spinner>
          </Form>
        );
      }}
    </Formik>
  );
};

FormAchievementsFilter.propTypes = {
  initialValues: PropTypes.shape({}).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default FormAchievementsFilter;
