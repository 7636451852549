import React from 'react';
import IntlMessages from '../layout/components/lang/IntlMessages';



export const DATE_TIME_FORMAT = 'DD MMM YYYY, HH:mm';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const EMPTY_VALUE_PLACEHOLDER = '—';
export const EMPTY_IMAGE_PLACEHOLDER = '/img/no-photo-available.png';
export const EMPTY_IMAGE_PREVIEW = '/img/no-pictures.png';
export const DEFAULT_SIDEBAR_WIDTH = 600;

export const dateFieldFormat = 'YYYY-MM-DD HH:mm';
export const requiredMessage = 'This field is a required';

export const targetPlatformOptions = [ {
  label: 'gp',
  value: 'gp',
}, {
  label: 'ios',
  value: 'ios',
}, {
  label: 'amazon',
  value: 'amazon',
} ];

export const statusOptions = [ {
  label: <IntlMessages id="ui-general-on" />,
  value: '1',
}, {
  label: <IntlMessages id="ui-general-off" />,
  value: '0',
} ];

export const STATUS_MAP = {
  ON: 'on',
  OFF: 'off',
};
