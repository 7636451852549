import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import range from 'lodash/range';
import { Empty, Skeleton } from 'antd';
import { DATE_TIME_FORMAT, EMPTY_IMAGE_PLACEHOLDER, STATUS_MAP } from '../../../../const/system';
import { useGetLanguagesQuery } from '../../../Translations/api/languagesApiSlice';



const NewsPriorityList = ({ data, isLoading, onOrderChange }) => {
  const intl = useIntl();
  const [ initOrder, setInitOrder ] = useState([]);
  const [ items, setItems ] = useState(initOrder);

  const { data: languages = { languages: [] } } = useGetLanguagesQuery({
    queryParams: 'limit=0',
  });

  useEffect(() => {
    if (data.length > 1) {
      const init = range(1, data.length + 1).map((item) => `${item}`);

      setInitOrder(init);
      setItems(init);
    }
  }, [ data ]);

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const initialOrder = [ ...items ];
    const [ reorderedItem ] = initialOrder.splice(result.source.index, 1);

    initialOrder.splice(result.destination.index, 0, reorderedItem);
    setItems(initialOrder);
    onOrderChange(initialOrder);
  };

  const dataTitle = (item) => {
    if (item?.dictionary?.data?.id) {
      const lang = languages.languages?.find((lang) => lang.code === 'ENG');

      if (!lang) {
        return null;
      }

      return item?.dictionary?.data?.structure?.data?.fields?.data?.map((field) => {
        const translation = item?.dictionary?.data?.translations?.data?.find((item) => {
          return item.structure_field_id === field.id && lang.id === item.language_id;
        });

        return (
          <p key={field.id} className="text-start hp-mb-0">
            {translation?.text}
          </p>
        );
      });
    }

    if (item?.legacy_dictionary) {
      return item?.legacy_dictionary?.translation_english?.title;
    }

    return '';
  };

  const willPublished = (values) => {
    if (values?.status === STATUS_MAP.OFF) {
      return (
        <>
          <hr />
          <span className="bold-sm-text">
            {`${intl.formatMessage({ id: 'ui-general-published-at' })}: ${dayjs(values?.published_at).format(DATE_TIME_FORMAT)}`}
          </span>
        </>
      );
    }

    return null;
  };

  return (
    <div>
      {isLoading ? <Skeleton /> : (
        <div>
          {!data.length && !isLoading ? (
            <Empty />
          ) : (
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="items">
                {(provided) => {
                  return (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                    <div className="news-priority-container" ref={provided.innerRef} {...provided.droppableProps}>
                      {items.map((item, index) => {
                        return (
                          <Draggable draggableId={item} index={index} key={item}>
                            {(provided) => {
                              const position = Number(item) - 1;

                              return (
                              // eslint-disable-next-line react/jsx-props-no-spreading
                                <div className={`news-priority-item ${data[position]?.status === STATUS_MAP.OFF ? 'news-priority-item-off' : ''}`} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                  <div className="hp-d-flex hp-align-items-center">
                                    <LazyLoadImage
                                      style={{
                                        height: '100px',
                                        width: '150px',
                                        objectFit: 'contain',
                                      }}
                                      effect='blur'
                                      alt="picture preview"
                                      src={data[position]?.icon}
                                      placeholderSrc={EMPTY_IMAGE_PLACEHOLDER}
                                    />
                                    <div className="hp-p-16">
                                      {dataTitle(data[position])}
                                      &nbsp;
                                      (ID:
                                      {data[position]?.id}
                                      )
                                      {willPublished(data[position])}
                                    </div>
                                  </div>
                                </div>
                              );
                            }}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  );
                }}
              </Droppable>
            </DragDropContext>
          )}
        </div>
      )}
    </div>
  );
};

NewsPriorityList.propTypes = {
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onOrderChange: PropTypes.func.isRequired,
};

export default NewsPriorityList;
