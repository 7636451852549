const features = {
  'features-breadcrumbs-daily': 'Ежедневные',
  'features-title-notification': 'Документрацию по данному методу можно найти здесь:',
  'features-breadcrumbs-editors-choice': 'Выбор редакторов',
  'features-breadcrumbs-external-promo': 'Внешние промо',
  'features-table-featured-column': 'Показ в рекомендуемых',
  'features-create-title': 'Создание данных',
  'features-update-title': 'Редактировать данные',
  'features-form-collapse-title-targets': 'Назначение',
  'features-form-image-id-label': 'ID изображения',
  'features-form-show-featured-label': 'Показ в рекомендуемых',
  'features-filter-bee-id-key-help': 'Можно вводить ID изображений из Би панели через запятую, пробелы использовать нельзя',
  'features-delete-notification': 'Удаление невозможно, данная запись уже опубликована',
  'features-use-utc': 'Использовать UTC+0',
  'features-title-info': 'Если данное поле оставить не заполненным, то оно заполнится автоматически',
  'features-title-use-automatic-generation-info': 'Заголовок генерируется автоматически',
  'features-use-utc-info': 'Данный параметр влияет на то, в какое время пользователь получит контент, в конкретное время сервера (вкл) или в конкретное время пользователя (выкл)',
  'features-show-in-features-info': 'Отображать сущность в разделе контента в мобильном приложении (Слайдер в верхней части экрана)',
  'features-details-unpublish': 'Снять с публикации из HC',
  'features-edit-images-to-attach': 'Изображения готовые для использования',
  'features-edit-images-to-attach-confirm-message': 'Вы уверены, что хотите использовать данное изображение? Все несохраненные изменения будут удалены.',
  'features-edit-images-to-attach-success': 'Изображение успешно прикреплено',
  'features-edit-images-to-attach-failed': 'Не удалось прикрепить изображение',
  'features-unpublish-failed': 'Ошибка при снятии с публикации',
  'features-edit-has-changes': 'Информируем о наличии обновлений для мобильного приложения HappyColor. Для успешной публикации этих изменений пользователю необходимо отметить соответствующий чекбокс, расположенный в правом нижнем углу экрана, рядом с кнопкой "Сохранить".',
  'features-edit-publish-to-hc': 'Опубликовать изменения в HappyColor',
  'features-not-featured-tag': 'Не в рекомендациях',
  'features-priority-update-past-disabled': 'Изменять порядок приоритета для прошедших дат запрещено',
  'features-priority-update-no-order-changed': 'Порядок приоритета не был изменён',
  'features-disabled-template-text-error': 'Шаблон выключен. Для того чтобы включить сущность, сперва включите шаблон.',
};

export default features;
