import React from 'react';
import { Popconfirm, Row, Tooltip } from 'antd';
import { PiDotsThreeOutlineFill, PiInfinity, PiPencil } from 'react-icons/pi';
import { RiErrorWarningLine } from 'react-icons/ri';
import { Delete } from 'react-iconly';
import { Eye } from 'iconsax-react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import { HasRights, PrivateComponent } from '../../../../../components/HasRights/HasRights';
import { Permissions } from '../../../../../const/permissions';
import urlPageBonusDetails from '../../../../../urls/urlPageBonusDetails';
import getFormattedDate from '../../../../../lib/getFormattedDate';
import CollapsibleText from '../../../../../components/CollapsibleText';
import PublishedTag from '../../../../../components/PublishedTag';
import {
  DATE_TIME_FORMAT,
  EMPTY_IMAGE_PREVIEW,
  EMPTY_VALUE_PLACEHOLDER,
} from '../../../../../const/system';



dayjs.extend(isSameOrBefore);
dayjs.extend(utc);


export const BONUSES_SOURCE = 'bonuses';

export const getBonusesTableColumns = (
  handleView,
  handleEdit,
  handleDelete,
  bonusesConfig,
  tableSize,
) => [
  {
    title: <IntlMessages id="ui-general-id" />,
    dataIndex: 'id',
    align: 'center',
    width: tableSize.width * 0.08,
    sorter: true,
  },
  {
    title: <IntlMessages id="ui-general-published" />,
    align: 'center',
    dataIndex: 'is_published',
    sorter: true,
    width: tableSize.width * 0.12,
    render: (value, item) => <PublishedTag published={Boolean(value)} publishedDate={item.published_at} />,
  },
  {
    title: <IntlMessages id="ui-general-code" />,
    dataIndex: 'code',
    align: 'center',
    width: tableSize.width * 0.08,
  },
  {
    title: <IntlMessages id="ui-general-title" />,
    dataIndex: 'title',
    width: tableSize.width * 0.12,
    align: 'center',
    render: (item, value) => {
      const rows = item.split('\n');

      return (
        rows.map((content, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index}>
            <CollapsibleText text={content} />
            &nbsp;
            {index === 0 && value?.is_published && dayjs(value.updated_at).isAfter(dayjs(value.published_at)) && (
              <Tooltip placement="right" title={<IntlMessages id="ui-general-unpublished-tooltip-title" />}>
                &nbsp;
                <ExclamationCircleOutlined className="hp-text-color-warning-1" />
              </Tooltip>
            )}
          </div>
        ))
      );
    },
  },
  {
    title: <IntlMessages id="ui-general-content" />,
    align: 'center',
    width: tableSize.width * 0.14,
    render: (value) => {
      const imagesLength = value.images?.data.length;
      const allowedImgCount = Math.floor((tableSize.width * 0.14) / 70);
      const hasCollapsedItems = imagesLength > allowedImgCount;

      return (
        <div className="hp-d-flex">
          {imagesLength > 0 ? (
            <>
              {value.images?.data?.slice(0, allowedImgCount).map((image) => {
                return (
                  <LazyLoadImage
                    className='lazy-load-img-list'
                    effect='blur'
                    alt="picture preview"
                    key={image.id}
                    src={image?.preview?.data?.path ?? EMPTY_IMAGE_PREVIEW}
                  />
                );
              })}
              {hasCollapsedItems && (
                <div
                  className='hp-align-self-center hp-cursor-pointer'
                  style={{ width: '30px' }}
                >
                  <Tooltip placement="top" title={<IntlMessages id="bonus-table-content-tooltip-label" />}>
                    <a
                      href={`${urlPageBonusDetails({ bonusId: value.id })}#attached-images`}
                      className="hp-text-color-black-80 hp-text-color-dark-60"
                    >
                      <PiDotsThreeOutlineFill size='14' />
                    </a>
                  </Tooltip>
                </div>
              )}
            </>
          ) : (
            <Tooltip placement="top" title={<IntlMessages id="ui-general-no-attached-images" />}>
              <LazyLoadImage
                className='lazy-load-img-list'
                effect='blur'
                alt="empty preview"
                src={EMPTY_IMAGE_PREVIEW}
              />
            </Tooltip>
          )}
        </div>
      );
    },
  },
  {
    title: <IntlMessages id="ui-general-released-at" />,
    dataIndex: 'released_at',
    align: 'center',
    sorter: true,
    width: tableSize.width * 0.12,
    render: (value) => getFormattedDate(value, DATE_TIME_FORMAT),
  },
  ...bonusesConfig?.release_range ? (
    [ {
      title: <IntlMessages id="ui-general-released-to" />,
      dataIndex: 'released_to',
      align: 'center',
      sorter: true,
      width: tableSize.width * 0.12,
      render: (value, item) => {
        if (!item.released_to) {
          return item.released_at ? <PiInfinity /> : EMPTY_VALUE_PLACEHOLDER;
        }
        return getFormattedDate(value, DATE_TIME_FORMAT);
      },
    } ]
  ) : [],
  ...(HasRights([
    Permissions.CONTENT.BONUSES.VIEW,
    Permissions.CONTENT.BONUSES.EDIT,
    Permissions.CONTENT.BONUSES.DELETE,
  ]) ?
    [ {
      title: <IntlMessages id="ui-general-action" />,
      key: 'action',
      align: 'center',
      width: tableSize.width * 0.13,
      render: (item) => {
        const canEditPastItem = bonusesConfig?.publish?.allow_update_past ?? true;
        const canEditItem = canEditPastItem ? true : dayjs().isSameOrBefore(dayjs(item?.released_at).subtract(-1, 'days'), 'day');

        return (
          <Row className="da-h-100" align="middle" justify="center">
            <PrivateComponent allowedPermissions={[ Permissions.CONTENT.BONUSES.VIEW ]}>
              <Tooltip placement="top" title={<IntlMessages id="ui-general-view" />}>
                <div className="hp-text-right">
                  <Eye
                    onClick={() => {
                      handleView(item.id);
                    }}
                    size={20}
                    className="hp-cursor-pointer hp-transition hp-hover-text-color-warning-1 hp-text-color-black-80 hp-m-4"
                  />
                </div>
              </Tooltip>
            </PrivateComponent>

            <PrivateComponent allowedPermissions={[ Permissions.CONTENT.BONUSES.EDIT ]}>
              {canEditItem && (
                <Tooltip placement="top" title={<IntlMessages id="ui-general-edit" />}>
                  <div className="hp-text-right">
                    <PiPencil
                      onClick={() => handleEdit(item.id)}
                      size={20}
                      className="hp-cursor-pointer hp-transition hp-hover-text-color-warning-1 hp-text-color-black-80 hp-m-4"
                    />
                  </div>
                </Tooltip>
              )}
            </PrivateComponent>

            <PrivateComponent allowedPermissions={[ Permissions.CONTENT.BONUSES.DELETE ]}>
              {!item.is_published ? (
                <Popconfirm
                  title={<IntlMessages id="ui-general-delete-confirm-message" />}
                  placement="top"
                  onConfirm={() => {
                    handleDelete(item.id);
                  }}
                  okText={<IntlMessages id="ui-general-yes" />}
                  cancelText={<IntlMessages id="ui-general-no" />}
                  icon={<RiErrorWarningLine className="remix-icon hp-text-color-primary-1" />}
                  okButtonProps={{ danger: true }}
                >
                  <Tooltip placement="top" title={<IntlMessages id="ui-general-delete" />}>
                    <div className="hp-text-right">
                      <Delete
                        size={20}
                        className="hp-cursor-pointer hp-transition hp-hover-text-color-danger-1 hp-text-color-black-80 hp-m-4"
                      />
                    </div>
                  </Tooltip>
                </Popconfirm>
              ) : (
                <Tooltip placement="top" title={<IntlMessages id="features-delete-notification" />}>
                  <div className="hp-text-right">
                    <Delete
                      disabled
                      size={20}
                      className="hp-opacity-4 hp-cursor-pointer hp-transition hp-text-color-black-80 hp-m-4"
                    />
                  </div>
                </Tooltip>
              )}
            </PrivateComponent>
          </Row>
        );
      },
    } ] : []
  ),
];

