import { apiSlice } from '../../../app/api/apiSlice';
import Config from '../../../configs/appConfig';
import makeQueryParams from '../../../lib/makeQueryParams';



export const ImagesApiSlice = apiSlice.injectEndpoints({
  tagTypes: [ 'Images' ],
  endpoints: (builder) => {
    return ({
      getCompressedCargo: builder.query({
        query: (params) => {
          const queryParams = makeQueryParams(params.queryParams);

          return ({ url: `/${Config.VITE_HC_API_VERSION}/compressed/cargo${queryParams}` });
        },
        transformResponse: (response) => {
          return {
            data: response.data,
            pagination: response.meta.pagination,
          };
        },
      }),
      getImages: builder.query({
        query: (params) => {
          const queryParams = makeQueryParams(params.queryParams);

          return ({ url: `/${Config.VITE_HC_API_VERSION}/images${queryParams}` });
        },
        transformResponse: (response) => {
          return {
            data: response.data,
            pagination: response.meta.pagination,
          };
        },
        providesTags: [ 'Images' ],
      }),
      attachImage: builder.mutation({
        query: ({ id, payload }) => {
          return {
            url: `/${Config.VITE_HC_API_VERSION}/images/${id}/attachments`,
            method: 'POST',
            body: payload,
          };
        },
        invalidatesTags: () => [ 'Images', 'Achievements', 'Achievement', 'Bonuses', 'BonusesItem', 'Collections', 'CollectionsItem' ],
      }),
      copyImage: builder.mutation({
        query: ({ id }) => {
          return {
            url: `/${Config.VITE_HC_API_VERSION}/images/${id}/copies`,
            method: 'POST',
          };
        },
        invalidatesTags: () => [ { type: 'Images' } ],
      }),
      updateImageCategories: builder.mutation({
        query: ({ id, payload }) => {
          return {
            url: `/${Config.VITE_HC_API_VERSION}/images/${id}/categories`,
            method: 'POST',
            body: payload,
          };
        },
        invalidatesTags: () => [ { type: 'Images' } ],
      }),
      reopenImage: builder.mutation({
        query: ({ id }) => {
          return {
            url: `/${Config.VITE_HC_API_VERSION}/images/${id}/state`,
            method: 'POST',
          };
        },
        invalidatesTags: () => [ { type: 'Images' } ],
      }),
    });
  },
});

export const {
  useGetCompressedCargoQuery,
  useGetImagesQuery,
  useAttachImageMutation,
  useCopyImageMutation,
  useReopenImageMutation,
  useUpdateImageCategoriesMutation,
} = ImagesApiSlice;
