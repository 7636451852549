import { Tooltip } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import { HasRights } from '../../../../components/HasRights/HasRights';
import { Permissions } from '../../../../const/permissions';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import appColors from '../../../../const/colors';



const BeeLink = ({ link, title }) => {
  const canViewBeepanel = HasRights([ Permissions.CONTENT.IMAGES.VIEW_BEEPANEL ]);

  if (canViewBeepanel) {
    return (
      <Tooltip placement="top" title={<IntlMessages id="ui-general-view" />}>
        <div style={{ color: appColors.lightIndigo, fontSize: '13px' }}>
          <a href={link} target="_blank" rel="noreferrer">
            {`Bee: ${title}`}
          </a>
        </div>
      </Tooltip>
    );
  }

  return (
    <div>
      <span>
        bee:
        &nbsp;
        {title}
      </span>
    </div>);
};

BeeLink.propTypes = {
  link: PropTypes.string.isRequired,
  title: PropTypes.any.isRequired,
};

export default BeeLink;
