import React, { useCallback, useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Button, Card, Col, Input, Row, Space, Table, Typography } from 'antd';
import { RiAddLine } from 'react-icons/ri';
import { Filter } from 'iconsax-react';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import { Search } from 'react-iconly';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import basePagination from '../../../../../const/pagination';
import ActionButton from '../../../../../layout/components/action-button';
import BreadCrumbs from '../../../../../layout/components/breadcrumbs';
import Sidebar from '../../../../../components/Sidebar';
import useToggle from '../../../../../app/hooks/useToggle';
import FormFilterPresets from '../../../forms/FormFilterPresets';
import useQueryParams from '../../../../../app/hooks/useQueryParams';
import getQueryParams from '../../../../../lib/getQueryParams';
import applyFilter from '../../../../../lib/applyFilter';
import resetFilter from '../../../../../lib/resetFilter';
import handleResponse from '../../../../../lib/handleResponse';
import makeTableLoadingObject from '../../../../../lib/makeTableLoadingObject';
import { getPresetsTableColumns } from './PagePresets.const';
import urlPageCreatePreset from '../../../../../urls/urlPageCreatePreset';
import { useDeletePresetMutation, useGetPresetsQuery } from '../../../api/presetsApiSlice';
import { Permissions } from '../../../../../const/permissions';
import { PrivateComponent } from '../../../../../components/HasRights/HasRights';



const { Title } = Typography;

const PagePresets = () => {
  const intl = useIntl();
  const [ isVisibleFilterSidebar, toggleFilterSidebar ] = useToggle();
  const [ initFilterValues, setInitFilterValues ] = useState({});
  const [ searchQueryParams, setSearchParams ] = useSearchParams();
  const [ searchField, setSearchField ] = useState('');

  const searchFields = [ 'title' ];
  const searchFilterFields = [ 'id', 'title', 'template_id', 'template.template_content_type_id' ];

  const {
    pagination,
    setSearchTerm,
    searchParams,
    handleChangeTableParams,
  } = useQueryParams({ searchFields, searchFilterFields, isFilter: true });

  const {
    data = { data: [], pagination: {} },
    isFetching,
  } = useGetPresetsQuery({
    queryParams: `include=template,user&${searchParams.toString()}`,
  });

  const [ deletePreset, { isLoading: isDeletingPreset } ] = useDeletePresetMutation();

  useEffect(() => {
    const queryParams = getQueryParams(window.location.search);

    if (queryParams.search) {
      const initValues = {};

      queryParams.search.split(';').forEach((item) => {
        const [ key, value ] = item.split(':');

        if (key === 'template.template_content_type_id') {
          initValues.template_type = value;
        } else {
          initValues[key] = value;
        }

        if (searchFields.includes(key)) {
          setSearchField(value);
        }
      });

      setInitFilterValues(initValues);
    }
  }, []);

  useEffect(() => {
    if (!searchParams.toString()) {
      setInitFilterValues({
        reset: true, // ugly hack
      });
    }
  }, [ searchParams ]);

  const handleSearch = useCallback((event) => {
    setSearchField(event.target.value);
    setSearchTerm(event.target.value);
  }, []);

  const handleDelete = (id) => {
    deletePreset({ id })
      .unwrap()
      .then(() => handleResponse('success', intl, 'ui-general-deleted-successfully'))
      .catch((error) => handleResponse('error', intl, 'ui-general-delete-failed', [], error));
  };

  return (
    <>
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-print-none hp-mb-32"
        align="middle"
      >
        <BreadCrumbs breadCrumbActive={<IntlMessages id='presets-breadcrumbs' />} />

        <Col md={12} span={24}>
          <Row
            gutter={[ 16, 16 ]}
            justify="end"
            align="middle"
          >
            <Col sm={12} span={24}>
              <Input
                allowClear={!isEmpty(searchField)}
                placeholder={intl.formatMessage({ id: 'ui-general-search-by-title' })}
                prefix={<Search set="curved" size={16} className="hp-text-color-black-80" />}
                value={searchField}
                onChange={handleSearch}
              />
            </Col>
            <Col>
              <Space size={16}>
                <PrivateComponent allowedPermissions={[ Permissions.CONSTRUCTORS.PRESETS.CREATE ]}>
                  <Link to={urlPageCreatePreset()}>
                    <Button
                      type="primary"
                      ghost="true"
                      icon={<RiAddLine className="btn-icon-mr-1" />}
                    >
                      <IntlMessages id='ui-general-create' />
                    </Button>
                  </Link>
                </PrivateComponent>

                <ActionButton
                  title=""
                  icon={<Filter size={18} />}
                  onClick={toggleFilterSidebar}
                />
              </Space>
            </Col>
          </Row>
        </Col>

        <Sidebar
          title={<Title level={5}>{intl.formatMessage({ id: 'presets-filter-title' })}</Title>}
          visible={isVisibleFilterSidebar}
          toggleSidebar={toggleFilterSidebar}
        >
          <FormFilterPresets
            initialValues={initFilterValues}
            isSubmitting={false}
            onReset={() => {
              resetFilter({
                searchQueryParams,
                setSearchParams,
                setInitFilterValues,
              });
            }}
            onSubmit={(values) => {
              applyFilter({
                values,
                searchQueryParams,
                searchFilterFields,
                setSearchParams,
                setSearchField,
                toggleFilterSidebar,
              });
            }}
          />
        </Sidebar>
      </Row>

      <Row gutter={[ 32, 32 ]}>
        <Col span={24}>
          <Card className="hp-border-color-black-40 hp-card-6">
            <Table
              sticky
              loading={makeTableLoadingObject(isFetching || isDeletingPreset)}
              rowKey="id"
              columns={getPresetsTableColumns(handleDelete)}
              dataSource={data.data}
              onChange={handleChangeTableParams}
              pagination={{
                current: pagination.page,
                pageSize: pagination.limit,
                total: data.pagination?.total,
                ...basePagination,
              }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};


export default PagePresets;

