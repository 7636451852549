import React from 'react';
import { Form, FormItem, Input, ResetButton, Select, SubmitButton } from 'formik-antd';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { Col, Row, Space, Tooltip } from 'antd';
import { useIntl } from 'react-intl';
import uniqBy from 'lodash/uniqBy';
import { InfoCircleOutlined } from '@ant-design/icons';
import Yup from '../../../../vendor/yup';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import { useGetTemplatesQuery } from '../../api/templatesApiSlice';
import mkTemplatesOptions from '../../../Content/utils/mkTemplatesOptions';
import makeSelectFilterOption from '../../../../lib/makeSelectFilterOption';



const validationSchema = Yup.object().shape({
  id: Yup.string()
    .min(1, 'Minimum 1 symbol required')
    .matches(/^\s*,?\s*\d+(?:\s*,\s*\d+)*\s*,?\s*$/, 'Only numbers and "," is allowed'),
  name: Yup.string(),
  template_type: Yup.string(),
  template_id: Yup.string(),
});

const FormFilterPreset = ({
  onSubmit,
  onReset,
  initialValues,
  isSubmitting,
  formErrors = {},
}) => {
  const intl = useIntl();
  const {
    data: { data: templates } = { data: [], pagination: {} },
    isFetching,
  } = useGetTemplatesQuery({
    queryParams: 'limit=0', // &search=status:1
  });

  const templateTypeOptions = () => {
    const templateTypes = uniqBy(templates, 'content_type.data.id');

    return templateTypes.map((templateType) => {
      return {
        label: templateType?.content_type?.data?.display_name,
        value: `${templateType?.content_type?.data?.id}`,
      };
    });
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      isSubmitting={isSubmitting}
      initialValues={initialValues}
      initialErrors={formErrors}
      onSubmit={(values) => {
        onSubmit({
          id: values.id,
          title: values.title,
          'template.template_content_type_id': values.template_type,
          template_id: values.template_id,
        });
      }}
      onReset={async (values) => {
        onReset(values);
      }}
    >
      {(props) => {
        // eslint-disable-next-line react/prop-types
        const { isValid, dirty = {} } = props;

        return (
          <Form layout="vertical" className='w-full'>
            <Row gutter={16}>
              <Col span={24}>
                <FormItem
                  className='ant-form-item-col stretched'
                  name="id"
                  label={
                    <Row className="w-full" justify="space-between" align="middle">
                      <IntlMessages id="ui-general-id" />
                      <Tooltip placement="topRight" title={<IntlMessages id="ui-general-filter-id-key-help" />}>
                        <InfoCircleOutlined />
                      </Tooltip>
                    </Row>
                  }
                >
                  <Input
                    name='id'
                    placeholder={intl.formatMessage({ id: 'presets-form-id-placeholder' })}
                  />
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem
                  className='ant-form-item-col'
                  name="title"
                  label={<IntlMessages id="ui-general-title" />}
                >
                  <Input
                    name='title'
                    placeholder={intl.formatMessage({ id: 'presets-form-title-placeholder' })}
                  />
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem
                  className='ant-form-item-col'
                  name="template_type"
                  label={<IntlMessages id="presets-form-template-type" />}
                >
                  <Select
                    loading={isFetching}
                    allowClear
                    name="template_type"
                    options={templateTypeOptions()}
                    placeholder={<IntlMessages id="presets-form-template-type-placeholder" />}
                  />
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem
                  className='ant-form-item-col'
                  name="template_id"
                  label={<IntlMessages id="ui-general-template" />}
                >
                  <Select
                    loading={isFetching}
                    allowClear
                    showSearch
                    name="template_id"
                    filterOption={(input, option) => makeSelectFilterOption(option.label, input)}
                    options={mkTemplatesOptions(templates)}
                    placeholder={<IntlMessages id="ui-general-choose-template" />}
                  />
                </FormItem>
              </Col>
            </Row>

            <Row gutter={[ 16, 16 ]} justify='end'>
              <Col>
                <Space>
                  <ResetButton disabled={false} loading={isSubmitting}>
                    <IntlMessages id='ui-general-reset' />
                  </ResetButton>
                  <SubmitButton
                    loading={isSubmitting}
                    disabled={!isValid || !dirty}
                  >
                    <IntlMessages id='ui-general-apply' />
                  </SubmitButton>
                </Space>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

FormFilterPreset.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    template_id: PropTypes.number,
  }).isRequired,
  formErrors: PropTypes.object,
  isSubmitting: PropTypes.bool.isRequired,
};

export default FormFilterPreset;

