import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Card, Col, Input, Modal, Row, Space, Table, Typography } from 'antd';
import { Search } from 'react-iconly';
import { Filter } from 'iconsax-react';
import isEmpty from 'lodash/isEmpty';
import { RiAddLine, RiCloseFill } from 'react-icons/ri';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import basePagination from '../../../../const/pagination';
import urlPageFeatureCreate from '../../../../urls/urlPageFeatureCreate';
import urlPageFeatureDetails from '../../../../urls/urlPageFeatureDetails';
import useToggle from '../../../../app/hooks/useToggle';
import useQueryParams from '../../../../app/hooks/useQueryParams';
import useElementSize from '../../../../app/hooks/useElementSize';
import getQueryParams from '../../../../lib/getQueryParams';
import getFeatureSourceFromUrl from '../../utils/getFeatureSourceFromUrl';
import getFeatureSourceKey from '../../utils/getFeatureSourceKey';
import getFeatureSourceTranslation from '../../utils/getFeatureSourceTranslation';
import getFeatureNotificationLink from '../../utils/getFeatureNotificationLink';
import getFeatureConfiguration from '../../utils/getFeatureConfiguration';
import { getFeaturesTableColumns } from './PageFeatures.const';
import { PrivateComponent } from '../../../../components/HasRights/HasRights';
import makeTableLoadingObject from '../../../../lib/makeTableLoadingObject';
import applyFilter from '../../../../lib/applyFilter';
import resetFilter from '../../../../lib/resetFilter';
import handleResponse from '../../../../lib/handleResponse';
import BreadCrumbs from '../../../../layout/components/breadcrumbs';
import DocumentationAlert from '../../../../components/DocumentationAlert/DocumentationAlert';
import ActionButton from '../../../../layout/components/action-button';
import Sidebar from '../../../../components/Sidebar';
import PreviewImageCard from '../../../Content/components/PreviewImageCard';
import PreviewDictionaryTranslation from '../../../Translations/components/PreviewDictionaryTranslation/PreviewDictionaryTranslation';
import FormFeaturesFilter from '../../forms/FormFeaturesFilter';
import { useGetLanguagesQuery } from '../../../Translations/api/languagesApiSlice';
import { useDeleteFeatureMutation, useGetFeaturesQuery } from '../../api/featuresApiSlice';
import { Permissions } from '../../../../const/permissions';



const { Title } = Typography;

const PageFeatures = () => {
  const intl = useIntl();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [ elementRef, size ] = useElementSize();
  const [ isVisibleFilterSidebar, toggleFilterSidebar ] = useToggle();
  const [ openDictionaryModal, toggleDictionaryModal ] = useToggle();
  const [ previewModalOpen, togglePreviewModal ] = useToggle();
  const [ previewImage, setPreviewImage ] = useState({});
  const [ initFilterValues, setInitFilterValues ] = useState({});
  const [ searchField, setSearchField ] = useState('');
  const [ previewDictionaryId, setPreviewDictionaryId ] = useState({});
  const [ searchQueryParams, setSearchParams ] = useSearchParams();

  const searchFields = [ 'title' ];
  const searchFilterFields = [ 'id', 'title', 'is_published', 'is_in_featured', 'published_at', 'source_id', 'image_id', 'released_at', 'released_to' ];

  const featureSource = getFeatureSourceFromUrl(pathname);
  const featureConfig = getFeatureConfiguration(featureSource);
  const featureSourceKey = getFeatureSourceKey(featureSource);
  const showImageAsPreview = featureConfig.config.preview.includes('content');

  const {
    pagination,
    search,
    setSearchTerm,
    searchParams,
    handleChangeTableParams,
  } = useQueryParams({ searchFields, searchFilterFields, isFilter: true });

  const { data = { data: [], pagination: {} }, isFetching } = useGetFeaturesQuery({
    queryParams: `${searchParams.toString()}${showImageAsPreview ? '&include=images' : ''}`,
    featureSource,
  });

  const { data: languages = { languages: [] } } = useGetLanguagesQuery({ queryParams: 'limit=0' });
  const [ deleteFeature, { isLoading: isDeletingFeature } ] = useDeleteFeatureMutation();


  useEffect(() => {
    const queryParams = getQueryParams(window.location.search);

    setSearchField(search);

    if (queryParams.search) {
      const initValues = {};

      queryParams.search.split(';').forEach((item) => {
        const [ key, value ] = item.split(':');

        if (value !== '') {
          initValues[key] = value;
        }
        if (searchFields.includes(key)) {
          setSearchField(value);
        }
      });

      setInitFilterValues(initValues);
    }
  }, [ featureSource ]);


  useEffect(() => {
    if (!searchParams.toString()) {
      setInitFilterValues({
        reset: true, // ugly hack
      });
      setSearchField('');
    }
  }, [ searchParams ]);


  const handleSearch = useCallback((event) => {
    setSearchField(event.target.value);
    setSearchTerm(event.target.value);
  }, []);

  const handlePreviewDictionary = (dictionaryId) => {
    setPreviewDictionaryId(dictionaryId);
    toggleDictionaryModal();
  };

  const handleViewDetails = (featureId) => {
    navigate(urlPageFeatureDetails({ featureSource, featureId }));
  };

  const handleDelete = (id) => {
    deleteFeature({ featureSource, id })
      .unwrap()
      .then(() => handleResponse('success', intl, 'ui-general-deleted-successfully'))
      .catch((error) => handleResponse('error', intl, 'ui-general-delete-failed', [], error));
  };

  const handleCardClick = (item) => {
    setPreviewImage(item);
    togglePreviewModal();
  };

  const handlePreviewModalClose = () => {
    togglePreviewModal();
  };

  return (
    <>
      <Modal
        width={previewImage.isImage ? 1200 : 500}
        title={intl.formatMessage({ id: 'content-image-preview-card-title' })}
        centered
        visible={previewModalOpen}
        onCancel={handlePreviewModalClose}
        footer={false}
        closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}
      >
        {previewImage.isImage ? (
          <PreviewImageCard
            data={previewImage.data}
            canUpdate={false}
            onModalClose={handlePreviewModalClose}
          />
        ) : (
          <img alt="icon" src={previewImage.data} />
        )}
      </Modal>

      <Modal
        title={<IntlMessages id="dictionaries-translation-preview" />}
        width="60%"
        centered
        destroyOnClose
        visible={openDictionaryModal}
        onCancel={toggleDictionaryModal}
        footer={null}
        closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}
      >
        <PreviewDictionaryTranslation
          dictionaryId={previewDictionaryId}
          languages={languages?.languages}
        />
      </Modal>

      <DocumentationAlert link={getFeatureNotificationLink(featureSource)} />
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-print-none hp-mb-32"
        align="middle"
      >
        <BreadCrumbs
          breadCrumbActive={
            <IntlMessages id={getFeatureSourceTranslation(featureSource)} />
          }
        />

        <Col md={16} span={24}>
          <Row
            gutter={[ 32, 32 ]}
            justify="end"
            align="middle"
          >
            <Col sm={12} span={24}>
              <Input
                key={featureSource}
                allowClear={!isEmpty(searchField)}
                placeholder={intl.formatMessage({ id: 'ui-general-search-by-title' })}
                prefix={<Search set="curved" size={16} className="hp-text-color-black-80" />}
                value={searchField}
                onChange={handleSearch}
              />
            </Col>
            <Col>
              <Space>
                <PrivateComponent allowedPermissions={[ Permissions.FEATURED.FEATURES[featureSourceKey]?.CREATE ]}>
                  <ActionButton
                    title={<IntlMessages id='ui-general-create' />}
                    icon={<RiAddLine className="btn-icon-mr-1" />}
                    onClick={() => navigate(urlPageFeatureCreate({ featureSource }))}
                  />
                </PrivateComponent>
                <ActionButton
                  title=""
                  icon={<Filter size={18} />}
                  onClick={toggleFilterSidebar}
                />
              </Space>
            </Col>
          </Row>
        </Col>

        <Sidebar
          key={featureSource}
          title={<Title level={5}>{intl.formatMessage({ id: 'ui-general-filter' })}</Title>}
          visible={isVisibleFilterSidebar}
          toggleSidebar={toggleFilterSidebar}
        >
          <FormFeaturesFilter
            initialValues={initFilterValues}
            isSubmitting={isFetching}
            featureConfig={featureConfig}
            onCancel={() => {
              resetFilter({
                searchQueryParams,
                setSearchParams,
                setInitFilterValues,
                callbacks: [ () => setSearchField('') ],
              });
            }}
            onSubmit={(values) => {
              applyFilter({
                values,
                searchQueryParams,
                searchFilterFields,
                setSearchParams,
                setSearchField,
                toggleFilterSidebar,
              });
            }}
          />
        </Sidebar>
      </Row>

      <Row gutter={[ 32, 32 ]}>
        <Col span={24}>
          <Card className="hp-border-color-black-40 hp-card-6">
            <div ref={elementRef}>
              <Table
                sticky
                scroll={{ x: 860 }}
                loading={makeTableLoadingObject(isFetching || isDeletingFeature)}
                rowKey="id"
                columns={
                  getFeaturesTableColumns(
                    handlePreviewDictionary,
                    handleViewDetails,
                    handleDelete,
                    handleCardClick,
                    showImageAsPreview,
                    featureConfig,
                    featureSource,
                    featureSourceKey,
                    size,
                  )
                }
                dataSource={data.data}
                onChange={handleChangeTableParams}
                pagination={{
                  ...basePagination,
                  current: pagination.page,
                  pageSize: pagination.limit,
                  total: data.pagination?.total,
                }}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PageFeatures;
