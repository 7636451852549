import React, { useCallback, useState } from 'react';
import { Card, Col, Input, Modal, Row, Space, Table, Typography } from 'antd';
import { RiAddLine, RiCloseFill } from 'react-icons/ri';
import { Search } from 'react-iconly';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import basePagination from '../../../../../const/pagination';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import BreadCrumbs from '../../../../../layout/components/breadcrumbs';
import { getPushIconsTableColumns } from './PagePushIcons.const';
import ActionButton from '../../../../../layout/components/action-button';
import useToggle from '../../../../../app/hooks/useToggle';
import handleResponse from '../../../../../lib/handleResponse';
import Sidebar from '../../../../../components/Sidebar';
import FormUploadPushIcon from '../../../forms/FormUploadPushIcon';
import useQueryParams from '../../../../../app/hooks/useQueryParams';
import makeTableLoadingObject from '../../../../../lib/makeTableLoadingObject';
import FormEditPushMessage from '../../../forms/FormEditPushMessage';
import {
  useCreatePushIconMutation,
  useDeletePushIconMutation,
  useGetPushIconsQuery,
  useUpdatePushIconMutation,
} from '../../../api/pushIconsApiSlice';



const { Title } = Typography;

const PagePushIcons = () => {
  const intl = useIntl();
  const [ isVisibleCreateSidebar, toggleSidebar ]  = useToggle();
  const [ isActivePreviewModal, togglePreviewModal ]  = useToggle();
  const [ currentImagePreview, setCurrentImagePreview ] = useState(null);
  const [ currentPush, setCurrentPush ] = useState({});

  const searchFields = [ 'id' ];

  const {
    pagination,
    search,
    setSearchTerm,
    searchParams,
    handleChangeTableParams,
  } = useQueryParams({ searchFields });

  const { data = { data: [], pagination: {} }, isLoading } = useGetPushIconsQuery({
    queryParams: `${searchParams.toString()}`,
  });
  const [ createPushIcon, { isLoading: isCreatingPushIcon } ] = useCreatePushIconMutation();
  const [ updatePushIcon, { isLoading: isUpdatingPushIcon } ] = useUpdatePushIconMutation();
  const [ deletePushIcon, { isLoading: isDeletingPushIcon } ] = useDeletePushIconMutation();

  const handleSearch = useCallback((event) => {
    setSearchTerm(event.target.value);
  }, []);

  const handlePreview = useCallback((previewImage) => {
    setCurrentImagePreview(previewImage);
    togglePreviewModal();
  }, [ currentImagePreview ]);

  const handleEdit = (data) => {
    setCurrentPush(data);
    toggleSidebar();
  };

  const handleDelete = (id) => {
    deletePushIcon(id)
      .unwrap()
      .then(() => handleResponse('success', intl, 'ui-general-deleted-successfully'))
      .catch((error) => handleResponse('error', intl, 'ui-general-delete-failed', [], error));
  };

  const handleCreatePushIcon = async (data) => {
    const formData = new FormData();

    formData.append('file', data.file);
    formData.append('folder', data.folder);
    formData.append('title', data.title);
    formData.append('text', data.text);

    try {
      await createPushIcon(formData).unwrap();

      toggleSidebar();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  const closeSidebar = () => {
    setCurrentPush({});
    toggleSidebar();
  };

  const handleEditPushMessage = (data) => {
    try {
      updatePushIcon({
        id: data.id,
        values: {
          title: data.title,
          text: data.text,
        },
      }).unwrap()
        .then(() => handleResponse('success', intl, 'ui-general-updated-successfully'))
        .catch((error) => handleResponse('error', intl, 'ui-general-update-failed', [], error))
        .finally(() => {
          setCurrentPush({});
          toggleSidebar();
        });
    } catch (error) {
      handleResponse('error', intl, 'push-icons-creating-failed', [], error);
    }
  };

  return (
    <>
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-print-none hp-mb-32"
        align="middle"
      >
        <BreadCrumbs breadCrumbActive={<IntlMessages id='push-icons-breadcrumbs' />} />

        <Col md={12} span={24}>
          <Row
            gutter={[ 32, 32 ]}
            justify="end"
            align="middle"
          >
            <Col md={12} span={24}>
              <Input
                allowClear={!isEmpty(search)}
                placeholder={intl.formatMessage({ id: 'push-icons-search' })}
                prefix={<Search set="curved" size={16} className="hp-text-color-black-80" />}
                value={search}
                onChange={handleSearch}
              />
            </Col>
            <Col>
              <Space>
                <ActionButton
                  title={<IntlMessages id='ui-general-create' />}
                  icon={<RiAddLine className="btn-icon-mr-1" />}
                  onClick={toggleSidebar}
                />
              </Space>
            </Col>
          </Row>
        </Col>

        <Sidebar
          title={currentPush?.id ? <Title level={5}><IntlMessages id='push-icons-update-title' /></Title> : <Title level={5}><IntlMessages id='push-icons-create-title' /></Title>}
          visible={isVisibleCreateSidebar}
          toggleSidebar={closeSidebar}
        >
          {currentPush?.id ? (
            <FormEditPushMessage
              initialValues={{
                id: currentPush.id,
                ...currentPush.message,
              }}
              onSubmit={handleEditPushMessage}
              onCancel={closeSidebar}
              isSubmitting={isUpdatingPushIcon}
            />
          ) : (
            <FormUploadPushIcon
              onSubmit={handleCreatePushIcon}
              onCancel={closeSidebar}
              isSubmitting={isCreatingPushIcon}
            />
          )}
        </Sidebar>
      </Row>

      <Modal
        title={<IntlMessages id="push-icons-preview-title" />}
        width={520}
        centered
        destroyOnClose
        visible={isActivePreviewModal}
        onCancel={togglePreviewModal}
        footer={null}
        closeIcon={
          <RiCloseFill className="remix-icon text-color-black-100" size={24} />
        }
      >
        <img
          alt="preview"
          src={currentImagePreview}
        />
      </Modal>

      <Row gutter={[ 32, 32 ]}>
        <Col span={24}>
          <Card className="hp-border-color-black-40 hp-card-6">
            <Table
              sticky
              loading={makeTableLoadingObject(isLoading || isDeletingPushIcon)}
              rowKey="id"
              columns={getPushIconsTableColumns(handlePreview, handleEdit, handleDelete)}
              dataSource={data.data}
              onChange={handleChangeTableParams}
              pagination={{
                ...basePagination,
                current: pagination.page,
                pageSize: pagination.limit,
                total: data.pagination?.total,
              }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PagePushIcons;
