import React from 'react';
import { Form, FormItem, Input, ResetButton, SubmitButton } from 'formik-antd';
import { Formik } from 'formik';
import { Col, Row } from 'antd';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import Yup from '../../../../vendor/yup';
import makePreventOnKeyDown from '../../../../lib/makePreventOnKeyDown';
import Spinner from '../../../../components/Spinner';



const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Min 3 symbols required')
    .max(255, 'Max 255 symbols required')
    .required('Name field is required'),
  code: Yup.string()
    .min(3)
    .max(4)
    .matches(/^[\w\-_\d]{3,4}$/, 'Only alphabets, digits and _, - are allowed for this field')
    .required('Code field is required'),
  description: Yup.string().nullable(),
});

const FormCreateEditAchievement = ({
  initialValues,
  initialErrors,
  onSubmit,
  onCancel,
  isSubmitting,
  isEdit = false,
}) => {
  const intl = useIntl();

  return (
    <Formik
      enableReinitialize
      isSubmitting
      validationSchema={validationSchema}
      initialValues={initialValues}
      initialErrors={initialErrors}
      onSubmit={async (values, { resetForm }) => {
        const preparedValues = {
          name: values.name,
          ...!isEdit && {
            code: values.code,
          },
          description: values.description,
        };

        await onSubmit(preparedValues, resetForm);
      }}
      onReset={async (values) => {
        onCancel(values);
      }}
    >
      {({ isValid, dirty }) => {
        return (
          <Form
            className="images-filter w-full"
            layout="vertical"
            onKeyDown={makePreventOnKeyDown}
          >
            <Spinner spinning={isSubmitting}>
              <Row>
                <Col span={24}>
                  <FormItem
                    label={intl.formatMessage({ id: 'ui-general-name' })}
                    name='name'
                    required
                  >
                    <Input
                      name="name"
                      placeholder={intl.formatMessage({ id: 'ui-general-specify-name' })}
                    />
                  </FormItem>
                </Col>

                <Col span={24}>
                  <FormItem
                    label={intl.formatMessage({ id: 'ui-general-code' })}
                    name='code'
                    required={!isEdit}
                  >
                    <Input
                      disabled={isEdit}
                      name="code"
                      placeholder={intl.formatMessage({ id: 'ui-general-specify-code' })}
                    />
                  </FormItem>
                </Col>

                <Col span={24}>
                  <FormItem
                    label={intl.formatMessage({ id: 'ui-general-description' })}
                    name='description'
                  >
                    <Input.TextArea
                      rows={3}
                      name="description"
                      type="text"
                      placeholder={intl.formatMessage({ id: 'ui-general-specify-description' })}
                    />
                  </FormItem>
                </Col>
              </Row>

              <Row gutter={[ 16, 16 ]} justify='end'>
                <Col>
                  <ResetButton disabled={false} loading={isSubmitting}>
                    <IntlMessages id='ui-general-reset' />
                  </ResetButton>
                </Col>

                <Col>
                  <SubmitButton
                    loading={isSubmitting}
                    disabled={!isValid || !dirty}
                  >
                    <IntlMessages id="ui-general-apply" />
                  </SubmitButton>
                </Col>
              </Row>
            </Spinner>
          </Form>
        );
      }}
    </Formik>
  );
};

FormCreateEditAchievement.propTypes = {
  initialValues: PropTypes.shape({}).isRequired,
  initialErrors: PropTypes.shape({}).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isEdit: PropTypes.bool,
};

export default FormCreateEditAchievement;
